export const environment = {
    production: false,
    apiUrl: 'https://erp.dev.devplus.com.br/api',
    versionApi: '/v1',
    clienteServiceApi: '/clientes',
    produtoServiceApi: '/produto',
    pagamentoServiceApi: '/condpgto',
    enderecoServiceApi: '/cadastrohelper/enderecov2',
    tagVersion: '[DC]',
};
