import { Component, OnInit, ViewChild } from '@angular/core';
import { PoBreadcrumb, PoDynamicViewField, PoModalComponent, PoTableColumn } from '@po-ui/ng-components';
import { PoPageDynamicTableActions, PoPageDynamicTableCustomAction, PoPageDynamicTableCustomTableAction, PoPageDynamicTableFilters } from '@po-ui/ng-templates';
import { SchemaBuilderService } from '../../core/menu/schema-builder.service';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'erpcloud-cliente',
    templateUrl: './cliente.component.html',
    styleUrls: ['./cliente.component.css']
})
export class ClienteComponent implements OnInit {

    @ViewChild('pedidosDetailModal') pedidosDetailModal!: PoModalComponent;

    readonly serviceApi = environment.apiUrl + '/v1/clientes';
    readonly partServiceApiProdu = "/v1/cotacao/by-client/";
    serviceApiProdu = "";

    tableProduSchema: PoTableColumn[] = [
        { property: 'codigo', label: 'Código' },
        { property: 'fantasia', label: 'Fantasia' },
        { property: 'data', label: 'Data Pedido', type: 'date', format: 'dd/MM/yyyy' },
        { property: 'dataN', label: 'Data NF', type: 'date', format: 'dd/MM/yyyy' },
        { property: 'valorT', label: 'Valor Total', type: 'currency', format: 'BRL' },
        { property: 'comprador', label: 'Comprador' }
    ];


    loading = false;
    editClient: any;
    fields: Array<PoPageDynamicTableFilters> = [];
    detailFields: Array<PoDynamicViewField> = [];
    pageCustomActions: Array<PoPageDynamicTableCustomAction> = [
        //{ label: 'Exportar .csv', selectable: true,  icon: 'po-icon-refresh' },
        //{ label: 'Print', action: this.onClickPecaDetail.bind(this) }
        {
            label: 'Imprimir',
            action: this.printPage.bind(this),
            icon: 'po-icon-print'
        }
    ];

    readonly breadcrumb: PoBreadcrumb = {
        items: [{ label: 'Home', link: '/home' }, { label: 'Cadastro' }, { label: 'Cadastro de Clientes' }]
    };

    readonly actions: PoPageDynamicTableActions = {

        new: '/clientes-edit/',
        edit: '/clientes-edit/:id',
        //duplicate: '/clientes-edit/',
        remove: true,

    };

    tableCustomActions: Array<PoPageDynamicTableCustomTableAction> = [
        {
            label: 'Visualizar Detalhes',
            icon: 'po-icon po-icon-eye',
            //action: this.onClickPecaDetail.bind(this),
        },
        {
            label: 'Pedidos',
            icon: 'po-icon po-icon-chart-area',
            //action: this.onClickOrdemDetail.bind(this),
        },
    ];
    constructor(private schemaBuilderService: SchemaBuilderService, private route: ActivatedRoute) { }

    ngOnInit(): void {
        //this.fields = this.route.snapshot.data['fields'];
        this.schemaBuilderService.getTableSchema("clientes").subscribe((data) => {
            this.fields = data;
        });
    }
    printPage() {
        window.print();
    }

    private onClickOrdemDetail(cliente: any) {

        console.log(cliente);
        console.log(cliente.codigo);

        this.editClient = cliente;

        this.serviceApiProdu = this.partServiceApiProdu + cliente.codigo;
        this.pedidosDetailModal.open();

    }

}