<!-- <div class="po-wrapper">
  <po-toolbar p-title="AppName"></po-toolbar>

  <po-menu [p-menus]="menus"></po-menu>

  <po-page-default p-title="AppName">
    <p> AppName page </p>
  </po-page-default>
</div> -->
<div class="po-wrapper">
  <po-toolbar *ngIf="isLoggedIn$ | async" p-title="{{customerName}} - {{tagVersion}}"
   [p-profile]="profile"
   [p-profile-actions]="profileActions"
   [p-show-notification]="true"
   [p-actions]="notificationActions"
   [p-notification-actions]="notificationActions2"
   [p-notification-number]="2"

   [p-show-notification]="true">
  </po-toolbar>
  <po-menu *ngIf="isLoggedIn$ | async"
  p-logo-alt="Devplus ERP - Cloud"
  [p-logo] ="logo"
  [p-short-logo]="shortLogo"
  p-short-logo=""
  [p-filter]="true" 
  [p-menus]="menus">
  
  <!-- [p-menus]="[{ label: 'Home', link: '/home', icon: 'po-icon po-icon-home', shortLabel: 'fsd' },
              { label: 'Empresas', link: '/empresas', icon: 'po-icon po-icon-company', shortLabel: 'fds' },
              { label: 'Certidões', link: '/certidoes', icon: 'po-icon po-icon-clipboard', shortLabel: 'dsd' },
              { label: 'Ginfes', link: '/', icon: 'po-icon po-icon-document', shortLabel: 'dsds' },
              { label: 'GISS - Fechamentos', link: '/', icon: 'po-icon po-icon-calendar', shortLabel: 'dsds' },
              { label: 'Status dos Serviços', link: '/status', icon: 'po-icon po-icon-pallet-partial', shortLabel: 'dsd' },
              { label: 'Configurações', link: '/status', icon: 'po-icon-settings', shortLabel: 'dsd' },
              ]"> -->

    <div *p-menu-header-template class="po-p-2 po-font-title sample-menu-header-text-color">
      <p>Bem Vindo, <b> {{userInfo?.name}} </b></p>
    </div>
  </po-menu>
  <router-outlet></router-outlet>
</div>
