<po-loading-overlay *ngIf="isLoadind" p-label="Salvando..." p-type="default"></po-loading-overlay>

<po-page-default p-title="Configurações Gerais do Sistema" [p-actions]="pageActions" [p-breadcrumb]="breadcrumb"
  p-subtitle="Configure opções de integração e configurações gerais da aplicação.">
  <hr />
  <form [formGroup]=configForm>
    <div class="po-row">
      <span class="po-lg-12 po-md-12 po-sm-12 po-mb-2 po-font-text-uppercase">
        <span class="ph ph-building-office"></span>
        Informações do Tenant</span>
      <po-input formControlName="id" p-help="Código principal de identificação do cliente" p-required="true"
        [p-show-required]="true" class="po-lg-6" p-disabled="true" p-icon="ph ph-gear" p-label="Tenant ID">
      </po-input>
      <po-input formControlName="name" p-help="Nome de identificação do tenant" p-required="true"
        [p-show-required]="true" class=" po-lg-6" p-disabled="true" p-icon="ph ph-user-circle-gear"
        p-label="Tenant Name">
      </po-input>
      <po-input formControlName="api" p-required="true" [p-show-required]="true" class=" po-lg-6" p-disabled="true"
        p-icon="ph ph-webhooks-logo" p-label="Backend API">
      </po-input>
      <po-input formControlName="host" p-required="true" [p-show-required]="true" class=" po-lg-2" p-disabled="true"
        p-icon="ph ph-git-branch" p-label="IaaS">
      </po-input>
      <po-input formControlName="apiVersion" p-required="true" [p-show-required]="true" class=" po-lg-2"
        p-disabled="true" p-icon="ph ph-git-branch" p-label="API">
      </po-input>
      <po-switch formControlName="producao" p-required="true" class=" po-lg-2" p-disabled="true"
        p-icon="ph ph-warning-circle" p-label="Ambiente Produtivo?" p-label-on="Sim" p-label-off="Não">
      </po-switch>
      <po-textarea formControlName="msgBoasVindas" [p-required]="true" class=" po-lg-12"
        p-help="Mensagem de Boas Vindas exibida na tela inicial do sistema." p-icon="ph ph-warning-circle"
        p-label="Mensagem Boas Vindas" p-label-on="Sim" p-label-off="Não">
      </po-textarea>
    </div>
    <hr />
    <div class="po-row">
      <span class="po-lg-12 po-md-12 po-sm-12 po-mb-2 po-font-text-uppercase">
        <span class="ph ph-address-book"></span>

        Cadastro do Cliente</span>

      <po-email formControlName="email" class="po-md-4" p-icon="po-icon po-icon-mail" p-label="Email">
      </po-email>
      <po-input formControlName="telefone" class="po-md-4" p-mask="(99) 99999-9999" p-icon="po-icon po-icon-telephone"
        p-label="Telefone">
      </po-input>
      <po-input formControlName="contato" class="po-md-4" p-icon="po-icon po-icon-user" p-label="Contato">
      </po-input>

      <div class="po-row">
        <po-input class="po-lg-8 po-sm-12 po-md-12" formControlName="cliAddress" p-icon="ICON_PIN" p-label="Endereço">
        </po-input>

        <po-input class="po-lg-2 po-sm-6 po-md-6" formControlName="cliCep" p-icon="ICON_PIN" p-mask="99999-999"
          p-required="true" [p-show-required]="true" p-label="CEP"></po-input>


        <po-input #numberInput class="po-lg-2 po-sm-6 po-md-6" formControlName="cliNumber" p-label="Número">
        </po-input>


      </div>

    </div>
    <hr />
    <div class="po-row">
      <span class="po-lg-12 po-md-12 po-sm-12 po-mb-2 po-font-text-uppercase">
        <span class="ph ph-stamp"></span>
        Configurações de Venda</span>

      <po-number formControlName="maxDiscount" class="po-lg-6"
        p-help="Desconto máximo que os vendedores poderão aplicar nos pedidos" [p-max]="10" [p-min]="0"
        p-icon="ph ph-seal-percent" p-label="Desconto Máximo">
      </po-number>
      <po-number formControlName="limitKm" class="po-lg-6"
        p-help="Limite máximo de KM que a obra pode ter da central configurada" [p-max]="999" [p-min]="0"
        p-icon="ph ph-road-horizon" p-label="Limite KM">
      </po-number>

      <po-number formControlName="metragemMinima" class="po-lg-6"
        p-help="Quantidade mínima permitida nos pedidos de vendas." [p-max]="999" [p-min]="1"
        p-icon="ph ph-less-than-or-equal" p-label="Quantidade Mínima">
      </po-number>
      <po-number formControlName="valorMetragemMinima" class="po-lg-6"
        p-help="Valor por metro que será cobrado até atingir a quantidade mínima" [p-max]="9999" [p-min]="1"
        p-icon="ph ph-money-wavy" p-label="Valor P/ Metro">
      </po-number>

      <po-number formControlName="totalTruck" class="po-lg-6"
        p-help="Limite de caminhões que poderão ser empenhados por sessão" [p-max]="999" [p-min]="0"
        p-icon="ph ph-truck" p-label="Limite de Caminhões">
      </po-number>
      <po-input formControlName="cepDistance" class="po-lg-6" p-mask="99999-999"
        p-help="CEP Que será usado para calcular a distância permitida para pedidos" p-icon="ph ph-map-pin-area"
        p-label="CEP Central">
      </po-input>


    </div>
    <hr />
    <div class="po-row">
      <span class="po-lg-12 po-md-12 po-sm-12 po-mb-2 po-font-text-uppercase">
        <span class="ph ph-plugs"></span>
        Integração - SERASA</span>

      <po-input formControlName="serasaApiUser" class="po-lg-6" p-icon="ph ph-user" p-label="Usuário Serasa">
      </po-input>
      <po-password formControlName="serasaApiPass" class="po-lg-6" p-icon="ph ph-password" p-label="Senha SERASA">
      </po-password>

    </div>
    <hr />
    <div class="po-row">
      <span class="po-lg-11 po-md-11 po-sm-11 po-mb-2 po-font-text-uppercase">
        <span class="ph ph-whatsapp-logo"></span>
        Integração - WhatsApp</span>
      <po-input formControlName="whatsAppPhone" class="po-lg-6" p-icon="ph ph-whatsapp-logo"
        p-label="Número do WhatsApp">
      </po-input>
      <po-password formControlName="whatsAppDeviceToken" class="po-lg-6" p-icon="ph ph-password"
        p-label="ID do Dispositivo">
      </po-password>
    </div>
  </form>
  <hr />


</po-page-default>