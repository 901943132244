import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { PoDialogService, PoNotificationService } from "@po-ui/ng-components";
import { PoPageLogin, PoPageLoginCustomField, PoPageLoginLiterals } from "@po-ui/ng-templates";
import { AuthService } from "../../core/auth/auth.service";
import { MenuService } from "../../core/menu/menu.service";


@Component({
  selector: 'erpcloud-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {

  customField: PoPageLoginCustomField = {
    property: 'domain',
    placeholder: 'Entre com o seu Domínio',

  };
  customLiterals: PoPageLoginLiterals = {
    attempts: '{0} vez(es) ',
    createANewPasswordNow: 'Melhor criar uma senha nova agora! Você vai poder entrar no sistema logo em seguida.',
    forgotPassword: 'Esqueceu sua senha?',
    forgotYourPassword: 'Esqueceu sua senha?',
    highlightInfo: '',
    iForgotMyPassword: 'Esqueci minha senha',
    ifYouTryHarder: 'Se tentar mais ',
    welcome: 'Bem vindo!',
    loginErrorPattern: 'Login incorreto...',
    loginHint: 'Caso não possua usuário entre em contato com o suporte',
    //loginLabel: 'Insira seu usuário',
    loginPlaceholder: 'Insira seu usuário de acesso',
    passwordErrorPattern: 'Senha obrigatória',
    //passwordLabel: 'Insira sua senha',
    passwordPlaceholder: 'Insira sua senha de acesso',
    customFieldErrorPattern: 'Campo customizado inválido',
    customFieldPlaceholder: 'Por favor insira um valor',
    registerUrl: 'Novo registro',
    rememberUser: 'Lembrar usuário',
    rememberUserHint: 'Esta opção pode ser desabilitada nas configurações do sistema',
    submitLabel: 'Acessar sistema',
    submittedLabel: 'Carregando...',
    titlePopover: 'Opa!',
    yourUserWillBeBlocked: 'sem sucesso seu usuário será bloqueado e você fica 24 horas sem poder acessar :('

  };
  loading: boolean = false;

  constructor(private poDialog: PoDialogService, private poNotification: PoNotificationService,
    private authService: AuthService,
    private menuService: MenuService,
    private router: Router) { }

  loginSubmit(formData: any) {
    this.loading = true;
    this.authService
      .authenticate(formData.login, formData.password, formData.domain.toUpperCase())
      .subscribe(() => {
        console.log('logou');
        this.menuService.triggerFunction();
        this.router.navigate(['home']);
      },
        err => {
          console.log(err);
          this.loading = false;
          this.poNotification.error('Usuário ou senha inválidos');
          //this.poDialog.alert({ title: 'Erro', message: 'Usuário ou senha inválidos', });
        })
  }


}