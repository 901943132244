<po-page-default [p-breadcrumb]="breadcrumb" p-title="Consulta de Orçamentos" [p-actions]="pageActions"
    p-subtitle="Consulte os seus orçamentos já cadastrados no sistema.">

    <div class="po-font-text-bold po-text-color-neutral-dark-40">Verifique os orçamentos já enviados no sistema</div>
    <po-divider></po-divider>

    <div class="po-row po-mb-4 shadow">
        <div>
            <po-loading-overlay [hidden]="isHideLoading"></po-loading-overlay>
        </div>
        <po-table #orcamentoTable p-container="border" [p-actions]="actions" class="po-mr-1 po-ml-1 po-lg-12" #wppTable
            (p-show-more)="loadMore()" [p-show-more-disabled]="!moreItens" p-striped="true" p-text-wrap="true"
            [p-spacing]="poTableColumnSpacing" p-container="border" p-hide-columns-manager="false"
            [p-hide-table-search]="false" [p-columns]="columns" [p-items]="this.tableItens">
            <ng-template p-table-column-template [p-property]="'condPgto.descricao'" let-value>
                <span [innerText]="value" class="po-font-text-large-bold"></span>
            </ng-template>
            <ng-template p-table-column-template [p-property]="'id'" let-value>
                <span [innerText]="value | padLeft:6" class="po-font-text-large-bold"></span>
                <!-- <div class="badge">{{ value | padLeft:6 }}</div> -->
            </ng-template>
        </po-table>
    </div>

</po-page-default>