import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PoModule } from '@po-ui/ng-components';
import { PoPageDynamicEditModule, PoPageDynamicTableModule, PoTemplatesModule } from '@po-ui/ng-templates';
import { OrcamentoComponent } from './orcamento.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClienteModule } from "../clientes/cliente.module";
import { ProdutoModule } from '../produto/produto.module';
import { OrcamentoAprovaComponent } from './orcamento-aprova/orcamento-aprova.component';
import { PadLeftPipe } from '../../core/pipes/pad-left.pipe';
import { SerasaModule } from "../serasa/serasa.module";
import { OrcamentoConsultaComponent } from './orcamento-consulta/orcamento-consulta.component';
import { OrcamentoAprovaComercialComponent } from './orcamento-aprova-comercial/orcamento-aprova-comercial.component';

@NgModule({
    declarations: [
        OrcamentoComponent,
        OrcamentoAprovaComponent,
        OrcamentoAprovaComercialComponent,
        OrcamentoConsultaComponent,
        PadLeftPipe
    ],
    exports: [
        OrcamentoComponent,
        OrcamentoAprovaComponent,
        OrcamentoAprovaComercialComponent,
        OrcamentoConsultaComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        PoModule,
        PoTemplatesModule,
        ClienteModule,
        ProdutoModule,
        SerasaModule,
        FormsModule
    ]
})
export class OrcamentoModule { }