import { Component, OnInit } from "@angular/core";
import { PoBreadcrumb } from "@po-ui/ng-components";
import { environment } from "../../../environments/environment";
import { PoPageDynamicTableCustomTableAction, PoPageDynamicTableFilters } from "@po-ui/ng-templates";
import { SchemaBuilderService } from "../../core/menu/schema-builder.service";

@Component({
    selector: 'erpcloud-receber',
    templateUrl: './receber.component.html',
    styleUrls: ['./receber.component.css']
})
export class ReceberComponent implements OnInit {

    readonly serviceApi = environment.apiUrl + '/v1/financial/receber';
    readonly breadcrumb: PoBreadcrumb = {
        items: [{ label: 'Home', link: '/home' }, { label: 'Financeiro' }, { label: 'Contas a Receber' }]
    };

    fields: Array<PoPageDynamicTableFilters> = [];

    tableCustomActions: Array<PoPageDynamicTableCustomTableAction> = [
        {
            label: 'Visualizar Detalhes',
            icon: 'po-icon po-icon-eye',
            //action: this.onClickPecaDetail.bind(this),
        },
        {
            label: 'Baixar Recebimento',
            icon: 'po-icon po-icon-chart-area',
            //action: this.onClickOrdemDetail.bind(this),
        },
    ];

    constructor(private schemaBuilderService: SchemaBuilderService) {
        this.schemaBuilderService.getTableSchema("receber").subscribe((data) => {
            this.fields = data;
        });
    }

    ngOnInit() { }
}