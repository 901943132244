import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EquipamentoComponent } from './equipamento.component';
import { EquipamentoEditComponent } from './equipamento-edit/equipamento-edit.component';
import { PoModule } from '@po-ui/ng-components';
import { PoPageDynamicTableModule,PoPageDynamicEditModule } from '@po-ui/ng-templates';

@NgModule({
    declarations: [
        EquipamentoComponent,EquipamentoEditComponent
    ],
    imports: [
        CommonModule,
        PoModule,
        PoPageDynamicTableModule,
        PoPageDynamicEditModule,
    ],
    exports: [
        EquipamentoComponent,EquipamentoEditComponent,PoPageDynamicEditModule
    ]
})
export class EquipamentoModule { }