import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfigComponent } from './config.component';
import { PoModule } from '@po-ui/ng-components';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [
        ConfigComponent
    ],
    imports: [
        CommonModule,
        PoModule,
        ReactiveFormsModule
    ],
    exports: [
        ConfigComponent,
    ]
})
export class ConfigModule { }