export class Produto {
    
    descricao?: string;
    codInterno?: string;
    unidade?: string;
    espTec1?: string;
    espTec2?: string;
    espTec3?: string;
    tipoMaterial?: string;
    tipoSeguimento?: string;
    precoLista?: number;
    estoque?: number;
    estoqueMinimo?: number;
    estoqueMaximo?: number;
    armazemId?: number;

}
