import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PoDynamicViewField } from "@po-ui/ng-components";
import { PoPageDynamicEditField, PoPageDynamicTableField, PoPageDynamicTableFilters } from "@po-ui/ng-templates";
import { environment } from "../../../environments/environment";

const API = environment.apiUrl;

@Injectable({providedIn:'root'})
export class SchemaBuilderService {
    constructor(private http: HttpClient) {
        this.http = http;
    }
    getTableSchema(tableKey: string) {
        return this.http.get<PoPageDynamicTableFilters[]>(API + "/v1/schema-builder/get-table-schema/" + tableKey);
    }
    getFieldSchema(tableKey: string) {
        return this.http.get<PoPageDynamicEditField[]>(API + "/v1/schema-builder/get-form-schema/" + tableKey);
    }
    getTableViewSchema(tableKey: string) {
        return this.http.get<PoDynamicViewField[]>(API + "/v1/schema-builder/get-table-schema/" + tableKey);
    }
}