import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalculadoraComponent } from './calculadora.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PoModule } from '@po-ui/ng-components';

@NgModule({
    declarations: [
        CalculadoraComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        PoModule,
        FormsModule
    ],
    exports: [
        CalculadoraComponent
    ]
})
export class CalculadoraModule { }