import { Component, OnInit } from '@angular/core';
import { PoBreadcrumb } from '@po-ui/ng-components';
import { PoPageDynamicEditActions, PoPageDynamicEditField } from '@po-ui/ng-templates';
import { SchemaBuilderService } from '../../../core/menu/schema-builder.service';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'erpcloud-bombas-edit',
    templateUrl: './bombas-edit.component.html',
    styleUrls: ['./bombas-edit.component.css']
})
export class BombasEditComponent implements OnInit {

        public readonly serviceApi = environment.apiUrl + '/v1/comercial/orcamento/bombas';

        public readonly breadcrumb: PoBreadcrumb = {
            items: [{ label: 'Home', link: '/' }, { label: 'Cadastro de Sessões Operacionais', link: '/bombas' }, { label: 'Edição de Bombas Operacionais' }]
        };
       
        //TODO: Implementar ações de salvar e salvar e novo
        public readonly actions: PoPageDynamicEditActions = {
            save: 'bombas',
        };

        fields: PoPageDynamicEditField[] = []
        
        constructor(private schemaBuilderService: SchemaBuilderService) { }

        ngOnInit(): void {
            this.schemaBuilderService.getFieldSchema("bombas").subscribe((data) => {
                this.fields = data;
            });
        }

}
