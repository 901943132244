import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FuncionarioComponent } from './funcionario.component';
import { FuncionarioEditComponent } from './funcionario-edit/funcionario-edit.component';
import { PoModule } from '@po-ui/ng-components';
import { PoPageDynamicTableModule,PoPageDynamicEditModule } from '@po-ui/ng-templates';

@NgModule({
    declarations: [
        FuncionarioComponent,FuncionarioEditComponent
    ],
    imports: [
        CommonModule,
        PoModule,
        PoPageDynamicTableModule,
        PoPageDynamicEditModule,
    ],
    exports: [
        FuncionarioComponent,FuncionarioEditComponent,PoPageDynamicEditModule
    ]
})
export class FuncionarioModule { }