import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Cliente } from "./cliente";

@Injectable({
    providedIn: 'root'
})
export class ClienteService {
    constructor(private http: HttpClient) { }

    getClienteById(idCliente: number) {
        return this.http.get<Cliente>(`${environment.apiUrl}${environment.versionApi}${environment.clienteServiceApi}/${idCliente}`);
    }
    saveCliente(cliente: Cliente) {
        return this.http.post<Cliente>(`${environment.apiUrl}${environment.versionApi}${environment.clienteServiceApi}`, cliente);
    }

}