import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BombasComponent } from './bombas.component';
import { BombasEditComponent } from './bombas-edit/bombas-edit.component';
import { PoModule } from '@po-ui/ng-components';
import { PoPageDynamicTableModule,PoPageDynamicEditModule } from '@po-ui/ng-templates';

@NgModule({
    declarations: [
        BombasComponent,BombasEditComponent
    ],
    imports: [
        CommonModule,
        PoModule,
        PoPageDynamicTableModule,
        PoPageDynamicEditModule,
    ],
    exports: [
        BombasComponent,BombasEditComponent,PoPageDynamicEditModule
    ]
})
export class BombasModule { }