import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PoModule } from '@po-ui/ng-components';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { PoTemplatesModule } from '@po-ui/ng-templates';
import { HomeModule } from './home/home.module';
import { CoreModule } from './core/core.module';

@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        PoModule,
        HomeModule,
        CoreModule,
        PoTemplatesModule], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class AppModule { }
