import { Component } from '@angular/core';

import { PoMenuItem, PoToolbarAction, PoToolbarProfile } from '@po-ui/ng-components';
import { MenuService } from './core/menu/menu.service';
import { Observable } from 'rxjs';
import { User } from './core/user/user';
import { UserService } from './core/user/user.service';
import { environment } from '../environments/environment';
import { TenantService } from './core/auth/tenant.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  logo ='';
  shortLogo = '';
  customerName ='';
  tagVersion ='';

  isLoggedIn$: Observable<boolean>;
  user$: Observable<User| null>;
  userInfo: User | null = null;
  userName: string = "";

  menus: PoMenuItem[] = [];
  constructor(private menuService: MenuService, private userService: UserService, private tenantService: TenantService)
  {
    this.menuService.triggerFunctionObservable.subscribe(() => {
      this.yourFunction();
    });

    this.user$ = userService.getUser();
    this.isLoggedIn$ = this.userService.isLoggedIn;
    this.user$.subscribe( x => {
      this.userInfo = x, 
      this.userName = x?.name as string

      this.profile = {
        avatar: 'https://po-ui.io/assets/graphics/logo-po.png',
        title: x?.name!,
        subtitle: x?.email! 
      };

    });
    this.menuService.getMenuSchema().subscribe(menus => this.menus = menus);

    this.tagVersion = environment.tagVersion;

    tenantService.getTenantInfo().subscribe((data) => { 

      this.logo = data.logo!;
      this.shortLogo = data.shortLogo!;
      this.customerName = data.name!;
      
    });


  }
  // readonly menus: Array<PoMenuItem> = [
  //   { label: 'Home', action: this.onClick.bind(this) }
  // ];
  private yourFunction() {
    this.user$ = this.userService.getUser();
    this.isLoggedIn$ = this.userService.isLoggedIn;
    this.user$.subscribe( x => {
      this.userInfo = x, 
      this.userName = x?.name as string;
      this.profile = {

        avatar: 'https://po-ui.io/assets/graphics/logo-po.png',
        subtitle: x?.name,
        title: x?.email! 
      };
    });

    this.menuService.getMenuSchema().subscribe(menus => this.menus = menus);
    this.tagVersion = environment.tagVersion;
    this.tenantService.getTenantInfo().subscribe((data) => { 

      this.logo = data.logo!;
      this.shortLogo = data.shortLogo!;
      this.customerName = data.name!;
      
    });

  }
  private onClick() {
    alert('Clicked in menu item')
  }
  logOut() {
    this.userService.logout();
   }
  profile: PoToolbarProfile = {

    avatar: 'https://po-ui.io/assets/graphics/logo-po.png',
    subtitle: this.userInfo?.name!,
    title: this.userInfo?.email!
  };


  profileActions: Array<PoToolbarAction> = [
    { icon: 'po-icon-user', label: 'Perfil' },
    { icon: 'po-icon-company', label: 'Info da Empresa' },
    { icon: 'po-icon-settings', label: 'Configuracões'},
    { icon: 'po-icon-exit', label: 'Logout', type: 'danger', separator: true, action: () => this.logOut()  }
  ];

  notificationActions2: Array<PoToolbarAction> = [
  ];

  notificationActions: Array<PoToolbarAction> = [
    {
      icon: 'po-icon-news',
      label: 'Novidades',
      type: 'default'
    },
    { 
      icon: 'po-icon-message', 
      label: 'Enviar mensagem', 
      type: 'default',
    }
  ];

}
