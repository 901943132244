<po-modal #consultaSerasaModal p-size="xl" p-title="Consulta SERASA" p-icon="ph ph-invoice" p-click-out="false">
    <!-- <po-loading [hidden]="isHiddenLoagind"></po-loading> -->
    <div class="po-row">
        <po-toaster class="po-md-12" p-message="Consultas do SERASA são cobradas conforme o plano contratado."
            p-support-message="Sempre verifique o valor da consulta com o seu gerente de contas SERASA."
            [p-type]="toasterType" p-show-close="false">

        </po-toaster>
    </div>
    <div class="po-row po-lg-10 po-mt-4">
        <div class="po-lg-10 po-font-title po-font-weight-bold">Integração - Consulta SERASA</div>
        <div class="po-lg-12 po-font-text po-text-color-neutral-dark-40">Cliente: {{cliente.razao}}</div>
        <div class="po-lg-12 po-font-text po-text-color-neutral-dark-40">Contato: {{cliente.contato}}</div>
        <div class="po-lg-12 po-font-text po-text-color-neutral-dark-40">CNPJ/CPF: {{cliente.cnpj}} {{cliente.cpf}}
        </div>
        <div class="po-lg-12 po-font-text po-text-color-neutral-dark-40">Fone: {{cliente.fone1}}</div>
    </div>
    <div class="po-row po-lg-2 po-mt-4">
        <po-image class="po-lg-12" [p-src]="srcImage" p-alt="Serasa"> </po-image>
    </div>
    <div class="po-row po-lg-12 po-mt-3 po-mb-3">
        <po-button [p-loading]="!isHiddenLoagind" class="po-md-12 po-lg-4 po-sm-12" (p-click)="consultaSerasa()"
            p-icon="ph ph-file-magnifying-glass" p-kind="primary" p-label="Consultar"></po-button>
    </div>

    <div class="po-row po-mt-5">
        <po-divider class="po-lg-12" p-label="Dados Consulta"></po-divider>
        <po-container class="po-lg-12">
            <po-tabs>
                <po-tab p-label="Retorno SERASA" [p-active]="true" p>

                    <form [formGroup]="form">
                        <div class="po-row">
                            <po-input class="po-md-12" formControlName="razao" p-clean p-icon="ph ph-user"
                                p-label="Razão/Nome" p-disabled="true"> </po-input>
                        </div>

                        <div class="po-row">
                            <po-input class="po-lg-9" formControlName="proposta" p-icon="ph ph-credit-card"
                                p-label="Número Proposta" p-disabled="true"> </po-input>

                            <po-number class="po-lg-3" formControlName="score" p-icon="ph ph-gauge" p-disabled="true"
                                p-label="Score SERASA">

                            </po-number>
                        </div>
                        <div class="po-row">
                            <po-input class="po-lg-12" formControlName="tipoRecomendacaoVenda" p-disabled="true"
                                p-icon="ph ph-megaphone" p-label="Recomendação de Venda"> </po-input>
                        </div>
                        <div class="po-row">
                            <po-input class="po-lg-12" formControlName="valorLimiteRecomendado" p-disabled="true"
                                p-icon="ph ph-money" p-label="Valor Recomendado"> </po-input>

                        </div>

                        <div class="po-row">
                            <po-textarea formControlName="msg" class="po-lg-12" p-label="Menssagem SERASA Recomenda"
                                [p-disabled]="true" [p-rows]="10">

                            </po-textarea>
                        </div>
                    </form>

                </po-tab>

                <po-tab p-label="Raw Data">
                    <form [formGroup]="formRaw">
                        <po-textarea class="po-md-12" formControlName="rawData" [p-disabled]="true" p-label="Raw Data"
                            [p-rows]="10">

                        </po-textarea>
                    </form>
                </po-tab>
            </po-tabs>
        </po-container>
    </div>

</po-modal>