import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { SchemaBuilderService } from '../../core/menu/schema-builder.service';

@Injectable({ providedIn: 'root' })
export class TableSchemaResolver implements Resolve<any> {
  constructor(private schemaBuilderService: SchemaBuilderService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    const tableName = state.url.split('/').pop();

    return this.schemaBuilderService.getTableSchema(tableName!);
  }
}