<po-page-dynamic-table
p-title="Cadastro de Clientes"

[p-quick-search-width]="4"
[p-table-custom-actions]="tableCustomActions"
[p-breadcrumb]="breadcrumb"
[p-concat-filters]="true"
[p-infinite-scroll]="true"
[p-service-api]="serviceApi"
[p-actions]="actions"
[p-fields]="fields"
[p-hide-columns-manager]="true"
[p-actions-right] = "false"
[p-page-custom-actions]="pageCustomActions">

</po-page-dynamic-table>


<po-modal #pedidosDetailModal p-icon="po-icon po-icon-document-filled" 
    [p-title]="'Pedidos Realizados - ' + editClient?.fantasia + ' (' + editClient?.cgc + ')'" p-size="xl">
    <span class="po-font-subtitle">Pedidos realizados</span>
    <po-divider [p-label]="'Lista de pedidos realizados por ' + editClient?.fantasia"></po-divider>
    <po-table
    [p-service-api]="serviceApiProdu"
    [p-striped]="true"
    [p-selectable]="true"
    [p-hide-columns-manager]="true"
    [p-columns]="tableProduSchema">
    </po-table>
</po-modal>