import { Component, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { PoBreadcrumb, PoChartOptions, PoChartSerie, PoChartType, PoModalComponent, PoTableColumn } from "@po-ui/ng-components";
import { ClienteAddComponent } from "../clientes/cliente-add/cliente-add.component";
import { TenantService } from "../../core/auth/tenant.service";
import { Tenants } from "../../core/auth/tenant";

@Component({
  selector: 'erpcloud-dash',
  templateUrl: './dash.component.html',
  styleUrls: ['./dash.component.css']
})
export class DashComponent {
  @ViewChild('detailsModal', { static: true }) detailsModalElement!: PoModalComponent;
  @ViewChild('clienteAddComponent') cadastroClienteModel!: ClienteAddComponent;

  paymentLink: string = 'https://www.google.com.br/search?q=days+to+payment';
  itemsDetails: Array<any> = [];
  titleDetailsModal: string = "";
  typeChart: string = 'line';
  currentDateTime: Date;
  chartType: PoChartType = PoChartType.Donut;

  totalOpenCotas = 0;
  totalOpenCotasExport = 0;
  totalOpenCotasInd = 0;

  tenantInfo: Tenants = new Tenants();

  optionsColumn: PoChartOptions = {
    axis: {
      minRange: -20,
      maxRange: 100,
      gridLines: 7
    }
  };

  categoriesColumn: Array<string> = ['Vendedor 1', 'Vendedor 2', 'Vendedor 3'];
  evolutionOfCoffeeAndSomeCompetitors: Array<PoChartSerie> = [
    { label: '2014', data: [91, 40, 42], type: PoChartType.Column },
    { label: '2017', data: [93, 52, 18], type: PoChartType.Column },
    { label: '2020', data: [95, 21, -17], type: PoChartType.Column },
    { label: '%', data: [34, 27, 79], type: PoChartType.Line, color: 'color-10' }
  ];

  public readonly columnsDetails: Array<PoTableColumn> = [
    { property: 'dateUpdate', label: 'Date update', type: 'date' },
    { property: 'statement', label: 'Statement', type: 'currency' }
  ];

  public readonly itemsAccountDetails: Array<any> = [
    { dateUpdate: '03-05-2018', statement: '-56.45' },
    { dateUpdate: '02-05-2018', statement: '-14.99' },
    { dateUpdate: '02-05-2018', statement: '-657.56' },
    { dateUpdate: '12-05-2017', statement: '3547.29' }
  ];

  public readonly itemsSavingsDetails: Array<any> = [
    { dateUpdate: '03-05-2018', statement: '-300' },
    { dateUpdate: '03-05-2018', statement: '2000' },
    { dateUpdate: '02-05-2018', statement: '1500' },
    { dateUpdate: '02-05-2018', statement: '-200' },
    { dateUpdate: '12-05-2017', statement: '2000' }
  ];

  constructor(private router: Router, tenantService: TenantService) {
    this.currentDateTime = new Date();
    tenantService.getTenantInfo().subscribe((data) => {
      this.tenantInfo = data;
    });
  }

  callerRota(rota: string) {
    this.router.navigate([rota]);
  }

  cadRapidoCli() {
    this.cadastroClienteModel.novoCliente();
  }

  daysPayment() {
    window.open(this.paymentLink, '_blank');
  }
}
