import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { environment } from "../../../environments/environment";
import { PoBreadcrumb, PoButtonComponent, PoDatepickerIsoFormat, PoInputComponent, PoModalComponent, PoMultiselectOption, PoNotification, PoNotificationService, PoRadioGroupComponent, PoRadioGroupOption, PoTableAction, PoTableColumn, PoTagType, PoToasterOrientation, PoToasterType } from "@po-ui/ng-components";
import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Cliente } from "../clientes/cliente";
import { ClienteService } from "../clientes/cliente.service";
import { CadastroHelperService } from "../../core/helpers/cadastro-helper.service";
import { EnderecoDtoV2 } from "../../core/helpers/endereco-dto";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { DistanceResponseDto } from "../../core/helpers/distance-response-dto";
import { DistanceRequestDto } from "../../core/helpers/distance-request-dto";
import { ClienteAddComponent } from "../clientes/cliente-add/cliente-add.component";
import { ProdutoAddComponent } from "../produto/produto-add/produto-add.component";
import { ProdutoService } from "../produto/produto.service";
import { Pedido, PedidoItem } from "./pedido";
import { OrcamentoService } from "./orcamento.service";
import { CondPgto } from "../condpgto/condpgto";
import { CondPgtoService } from "../condpgto/condpgto.service";
import { Router } from "@angular/router";
import { RaioDto } from "./raio-dto";
import { Tenants } from "../../core/auth/tenant";
import { TenantService } from "../../core/auth/tenant.service";
import { SimpleDistanceResponseDto } from "../../core/helpers/simple-distance-request-dto";
import { UserService } from "../../core/user/user.service";
import { User } from "../../core/user/user";
import { Vendedor } from "../vendedor/vendedor";
import { CurrencyPipe } from "@angular/common";


@Component({
    selector: 'erpcloud-orcamento',
    templateUrl: './orcamento.component.html',
    styleUrls: ['./orcamento.component.css']
})
export class OrcamentoComponent implements OnInit {

    //Controles de Tela
    @ViewChild('numberInput') numberInput!: PoInputComponent;
    @ViewChild('cepButton') cepButton!: PoInputComponent;
    @ViewChild('clienteAddComponent') cadastroClienteModel!: ClienteAddComponent;
    @ViewChild('produtoAddComponent') produtoAddComponent!: ProdutoAddComponent;
    @ViewChild(PoRadioGroupComponent, { static: true }) radioGroupComponent!: PoRadioGroupComponent;


    //APIs
    readonly serviceSections = environment.apiUrl + environment.versionApi + '/comercial/orcamento/get-sections';
    readonly serviceBombas = environment.apiUrl + environment.versionApi + '/comercial/orcamento/get-bombas';
    readonly clienteServiceApi = environment.apiUrl + environment.versionApi + environment.clienteServiceApi;
    readonly pagamentoServiceApi = environment.apiUrl + environment.versionApi + environment.pagamentoServiceApi;
    readonly produtoServiceApi = environment.apiUrl + environment.versionApi + environment.produtoServiceApi;
    readonly breadcrumb: PoBreadcrumb = {
        items: [{ label: 'Home', link: '/home' }, { label: 'Vendas' }, { label: 'Novo Orçamento' }]
    };

    poDatepickerIsoFormat: PoDatepickerIsoFormat = PoDatepickerIsoFormat.Extended;

    //Variaveis de controles de colunas da tabela de produtos
    readonly actions: PoTableAction[] = [
        {
            label: 'Verificar Disponibilidade',
            icon: 'ph ph-paper-plane-tilt',
        },
        {
            label: 'Excluir Item',
            type: 'danger',
            icon: 'ph ph-trash',
            action: this.removeItem.bind(this),
            separator: true
        }
    ];
    public poOptions: Array<PoRadioGroupOption> = [
        // { label: 'Sem Bomba', value: '1' },
        // { label: 'BT Bomba', value: '2' },
        // { label: 'Bomba Simples', value: '3' }
    ]
    public readonly adicionalOptions: Array<PoMultiselectOption> = [
        { value: 'distancia', label: 'Distânacia maior que permitida' },
        { value: 'desmontagemComplexa', label: 'Desmontagem de bomba (complexidade)' },
        { value: 'metroLinear', label: 'Metro lianear mangueira maior que permitido' },
        { value: 'dificilAcesso', label: 'Local de difícil acesso' },
        { value: 'metragemAbaixoPadrao', label: 'Metragem cubica abaixo do padrão' }
    ];

    public readonly columns: Array<PoTableColumn> = [
        {
            property: 'codigoInterno',
            label: 'Código',
            type: 'link'
        },
        {
            property: 'descricao',
            label: 'Descrição',
            type: 'string'
        },
        {
            property: 'quant',
            label: 'Quantidade(M3)',
            type: 'number',
        },
        {
            property: 'valor',
            label: 'Valor Un.',
            type: 'currency',
            format: 'BRL'
        },
        {
            property: 'valorTotal',
            label: 'Valor Total',
            type: 'currency',
            format: 'BRL'
        },

    ];

    //Variáveis de Controle
    isHideLoading: boolean = true;
    cep: string = '';
    poTag: PoTagType = PoTagType.Success;
    cliente$: Observable<Cliente> | undefined;
    clienteChange: Cliente | undefined;
    enderecoForm!: UntypedFormGroup;
    clienteForm!: UntypedFormGroup;
    adicionalForm!: UntypedFormGroup;
    cepLoading: boolean = false;
    confirmaLoading: boolean = false;
    totalKm: number = 0;
    distance: string = '';
    clienteToInsert!: Cliente;
    condPgto: CondPgto | undefined;
    poToasterType: PoToasterType = PoToasterType.Warning;
    //Pedido de Venda
    pedido: Pedido = new Pedido();
    pedidoItens: PedidoItem[] = [];
    idSection: number = 0;
    volumePermitido: number = 0;
    dataEntrega: Date = new Date();
    bombaSelecionada: number = 1;
    msgBomba: string = '';
    titleMsgBomba: string = '';
    valorMinimoBomba: number = 0;
    valorMetroBomba: number = 0;
    valorTotalBomba: number = 0;
    percentualAcrescimo: number = 0;
    totalAcrescimo: number = 0;
    totalDesconto: number = 0;
    totalDescontoVendedor: number = 0;
    percentualDesconto: number = 0;
    totalDescontoPedido: number = 0;
    raioConfigs: RaioDto[] = [];
    poTagDanger: PoTagType = PoTagType.Danger;
    poTagSuccess: PoTagType = PoTagType.Success;
    temValorAdicional: boolean = false;
    valorAdicional: number = 0;
    tenantInfo: Tenants = new Tenants();
    userInfo!: User;
    vendedor!: Vendedor;

    constructor(private fb: UntypedFormBuilder,
        private currencyPipe: CurrencyPipe,
        private router: Router,
        private userService: UserService,
        private tenantService: TenantService,
        private clienteService: ClienteService,
        private condPgtoService: CondPgtoService,
        private produtoService: ProdutoService,
        private orcamentoService: OrcamentoService,
        private poNotification: PoNotificationService,
        private cadastroHelper: CadastroHelperService) {

        this.createEnderecoForm();
        this.createAdicionalForm();

    }

    ngOnInit(): void {
        this.userService.getUser().subscribe((data) => {
            this.userInfo = data as User;
            this.orcamentoService.getVendedorById(this.userInfo.id_vendedor).subscribe((data) => {
                this.vendedor = data;
                console.log(this.vendedor);

                if (this.vendedor.id == 0) {
                    this.poNotification.error('Usuário sem vendedor associado, favor verificar cadastro');
                    this.router.navigate(['/home']);
                }
            });
        });
        this.tenantService.getTenantInfo().subscribe(tenant => {
            this.tenantInfo = tenant;
        });

        this.orcamentoService.getTipoBombas().subscribe(data => {
            this.poOptions = data;
            this.bombaSelecionada = 1;
        });

        this.orcamentoService.getRaioConfigs().subscribe(data => {
            this.raioConfigs = data;
            console.log(this.raioConfigs);
        });
        this.produtoService.itemAdd$.subscribe(item => {
            if (item) {
                this.pedidoItens.push(item);
                console.log(this.pedidoItens);
                this.calculaValorBomba();
                this.calculaAcrescimo();
            }
        });

        this.produtoService.clearItems$.subscribe(() => {
            this.pedidoItens = [];
            console.log('Itens do pedido foram limpos:', this.pedidoItens);
        });

    }
    removeItem(itemRemove: any) {
        this.produtoService.itemRemove$.subscribe(item => {
            if (itemRemove) {
                const index = this.pedidoItens.findIndex(pedidoItem => pedidoItem.codigoInterno === itemRemove.codigoInterno);
                if (index !== -1) {
                    this.pedidoItens.splice(index, 1);
                    this.calculaValorBomba();
                    this.calculaAcrescimo();
                    console.log(this.pedidoItens);
                }
            }
        });
    }
    createEnderecoForm() {
        this.enderecoForm = this.fb.group({
            cepButton: [''],
            address: ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(50)])],
            number: ['', Validators.compose([Validators.required, Validators.min(1), Validators.max(99999)])],
            bairro: ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(30)])],
            city: ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(30)])],
            uf: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(30)])],
            limiteVolume: ['5', Validators.compose([Validators.required])],
            horaEntrega: ['', Validators.compose([Validators.required])],
            prevFaturamento: [new Date().toISOString().split('T')[0], Validators.compose([Validators.required])],
            obsPedido: ['']
        });
    }
    createAdicionalForm() {
        this.adicionalForm = this.fb.group({
            valorAdicional: ['0', Validators.compose([Validators.required])],
            motivoValorAdicional: [''],
        });
    }
    incluirItem() {
        console.log(this.bombaSelecionada);
        this.produtoAddComponent.addProduct();
        console.log(this.pedidoItens);

        this.calculaValorBomba();
        this.calculaAcrescimo();
        //this.valorTotalPedido = this.getTotalValorPedido();

        //this.produtoAddComponent.closeModal();
    }
    calculaValorBomba() {
        this.valorTotalBomba = Number(this.totalVolume) * Number(this.valorMetroBomba);
        if (this.valorTotalBomba < this.valorMinimoBomba) {
            this.valorTotalBomba = this.valorMinimoBomba;
        }

        console.log('Valor da bomba:' + this.valorTotalBomba);
    }

    get totalQuantidade(): number {
        return this.pedidoItens.reduce((sum, item) => {
            const valorTotal = Number(item.valorTotal ?? 0);
            return sum + valorTotal;
        }, 0);
    }
    get totalVolume(): number {
        return this.pedidoItens.reduce((sum, item) => sum + item.quant!, 0);
    }
    calculaAcrescimo() {
        this.percentualAcrescimo = this.getAcrecimoRaio();
        this.totalAcrescimo = this.totalQuantidade * (this.percentualAcrescimo / 100);
        //(totalAcrescimo / 100) * totalQuantidade
        console.log('Acrescimo:' + this.totalAcrescimo);
    }

    calculaDescontoVendedor(event: any) {
        console.log(event);
        if (event) {
            this.totalDescontoVendedor = (this.totalQuantidade + this.totalAcrescimo) * (this.vendedor.maxDiscount / 100);
        }
        else {
            this.totalDescontoVendedor = 0;
        }
    }
    getAcrecimoRaio(): number {

        let acrescimo = 0;

        for (let raio of this.raioConfigs) {
            if ((this.totalKm / 1000) < raio.raio) {
                return raio.acrescimo;
            }
        }

        return acrescimo;
    }
    onChangePgto(idPgto: number) {

        if (!idPgto) {
            this.condPgto = undefined;
            return;
        }

        this.condPgtoService.getCongPgto(idPgto).subscribe((data) => {
            this.condPgto = data;
            console.log(this.condPgto);

            this.percentualDesconto = this.condPgto?.desconto ?? 0;
            this.totalDesconto = (this.totalQuantidade + this.totalAcrescimo) * (this.percentualDesconto / 100);

        });
    }
    onChangeCliente(idCliente: number) {

        if (!idCliente) {
            this.clienteChange = undefined;
            return;
        }
        this.clienteService.getClienteById(idCliente).subscribe((data) => {

            this.enderecoForm.get('cepButton')?.setValue(data.cep);
            if (data.limite !== null && data.limite !== undefined && data.limite > 0) {

                this.clienteChange = data;
                console.log(this.clienteChange);

            } else {
                const poNotification: PoNotification = {
                    message: 'Cliente ' + data.razao + ' sem linha de crédito cadastrada',
                    orientation: PoToasterOrientation.Top,
                    // action: () => {
                    //     // Navegar para o componente desejado
                    //     this.router.navigate(['/clientes-edit/' + data.id]);
                    // },
                    actionLabel: 'Verificar Cadastro',
                    duration: 8000
                }

                this.poNotification.information(poNotification);
                this.clienteChange = data;

                console.log(this.clienteChange);
            }

        });
    }
    consultaCep() {
        this.cepLoading = true;
        this.cadastroHelper.getEnderecoByCep(this.cep).subscribe((data: EnderecoDtoV2) => {
            console.log(data);
            this.enderecoForm.get('address')?.setValue(data.street);
            this.enderecoForm.get('city')?.setValue(data.city);
            this.enderecoForm.get('bairro')?.setValue(data.neighborhood);
            this.enderecoForm.get('uf')?.setValue(data.state);

            this.numberInput.focus();

            const requestDto = new DistanceRequestDto();
            requestDto.mode = 'driving';
            requestDto.origin = this.tenantInfo.cepDistance;
            requestDto.destination = this.cep;

            this.cadastroHelper.getDistanceBetweenCepsGoogle(requestDto).subscribe((data: SimpleDistanceResponseDto) => {
                console.log(data);
                this.totalKm = Number(data.distanceValue ?? 0);
                this.distance = data.distanceText ?? '';
                console.log(this.totalKm);

                const poNotification: PoNotification = {
                    message: 'Tempo estimado de percurso: ' + data.durationText + ' - Distância: ' + data.distanceText +
                        ' - Limite permitido: ' + this.tenantInfo.limitKm + ' Km',
                    orientation: PoToasterOrientation.Top,
                    // action: () => {
                    //     // Navegar para o componente desejado
                    //     this.router.navigate(['/clientes-edit/' + data.id]);
                    // },
                    duration: 5000
                }

                if (this.totalKm <= Number(this.tenantInfo?.limitKm) * 1000) {
                    poNotification.message = 'Entrega garantida. ' + poNotification.message;
                    this.poNotification.information(poNotification);
                }
                // else if (this.totalKm < Number(this.tenantInfo?.limitKm!)) {
                //     poNotification.message = 'Antenção na entrega - Percurso longo. ' + poNotification.message;
                //     this.poNotification.warning(poNotification);
                // }
                else {
                    poNotification.message = 'Entrega indisponínel - Percurso muito longo. \n' + poNotification.message;
                    this.poNotification.error(poNotification);
                }
                this.cepLoading = false;
                this.calculaAcrescimo();
            },
                (error) => {
                    console.error('Erro ao buscar dados:', error);
                    this.isHideLoading = true;
                    this.cepLoading = false;

                })

        },
            (error) => {
                console.error('Erro ao buscar dados:', error);
                this.isHideLoading = true;
                this.cepLoading = false;

            });
    }
    onCepChange(event: any) {

        this.cep = event;
        if (this.cep.length == 9) {
            //this.cepButton.focus();
        }

        console.log(this.cep);
    }
    onChangeValorAdicionalNumber() {
        let valor = this.adicionalForm.get('valorAdicional')?.value;
        valor = this.currencyPipe.transform(valor, 'BRL', 'symbol', '1.2-2');
        this.adicionalForm.get('valorAdicional')?.setValue(valor, { emitEvent: false });
    }
    verificaCliente(step: number) {
        console.log(step);
        return this.clienteChange == undefined ? false : true;
    }
    verificaProduto(step: number) {
        console.log(step);
        return this.pedidoItens.length == 0 ? false : true
    }
    verificaCondicao(step: number) {
        console.log(step);
        return this.condPgto == undefined ? false : true;
    }
    verificaEndereco(step: number) {

        //Preenchimento de valores para orçcmento
        this.enderecoForm.markAllAsTouched();
        this.idSection = this.enderecoForm.get('horaEntrega')?.value;
        this.volumePermitido = this.enderecoForm.get('limiteVolume')?.value;
        this.dataEntrega = new Date(this.enderecoForm.get('prevFaturamento')?.value);

        console.log(step);
        return this.enderecoForm.valid;
    }
    onChangeValorAdicional(event: boolean) {

        this.temValorAdicional = event;
        console.log(this.enderecoForm.get('motivoValorAdicional')?.value);

        if (!event) {
            this.adicionalForm.get('valorAdicional')?.setValue('0');
            this.valorAdicional = 0;
        }
    }
    onChangeAdicional(event: any) {
        console.log(event);
        this.valorAdicional = this.adicionalForm.get('valorAdicional')?.value;
    }
    onChangeMotivoAdicional(event: any) {
        console.log(event);
    }
    selecionaBomba(value: number) {
        let bombaSelecionada = this.poOptions.find(bomba => bomba.value == this.bombaSelecionada) as any;
        this.msgBomba = 'Valor mínimo: ' + this.formatarParaMoeda(bombaSelecionada.valorMinimo) + ' - Valor metro: ' + this.formatarParaMoeda(bombaSelecionada.valorMetro);
        this.titleMsgBomba = bombaSelecionada.label + ' Selecionada';

        this.valorMinimoBomba = Number(bombaSelecionada.valorMinimo);
        this.valorMetroBomba = Number(bombaSelecionada.valorMetro);

        this.calculaValorBomba();
    }
    formatarParaMoeda(valor: number): string {
        return valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }
    finalizarPedido() {

        this.confirmaLoading = true;

        //Envio de pedido para API
        this.pedido = new Pedido();
        this.pedido.dataInclusao = new Date();
        this.pedido.contato = this.clienteChange?.contato;
        this.pedido.comprador = this.clienteChange?.contato;
        this.pedido.idCliente = this.clienteChange?.id;
        this.pedido.cp = 'C';
        this.pedido.pedidoItens = this.pedidoItens;
        this.pedido.expedicao = false;
        this.pedido.needColeta = false;
        this.pedido.obsNf = '';
        this.pedido.obsColeta = '';
        this.pedido.obsNgo = this.enderecoForm.get('obsPedido')?.value;
        this.pedido.freteCliente = false;
        this.pedido.dtExpedicao = new Date();
        this.pedido.dataProrroga = new Date();
        this.pedido.dataBaixaColeta = new Date();
        this.pedido.coletado = false;
        this.pedido.idPgto = this.condPgto?.id;
        this.pedido.qtdKm = Math.round(this.totalKm / 1000);
        this.pedido.idSection = this.idSection;
        this.pedido.prevFaturamento = this.dataEntrega.toISOString();
        this.pedido.prevEntrega = this.dataEntrega;
        this.pedido.qtdTruckSuggested = Math.round(this.totalVolume / this.volumePermitido);

        //Bombas
        this.pedido.idBomba = this.bombaSelecionada;
        this.pedido.valorBomba = this.valorTotalBomba;
        if (this.bombaSelecionada > 0)
            this.pedido.qtdBomb = 1;
        else
            this.pedido.qtdBomb = 0;

        this.pedido.valorProdutos = this.totalQuantidade;
        this.pedido.valorAdicional = this.temValorAdicional ? this.valorAdicional : 0;
        //this.pedido.motivoValorAdicional = this.temValorAdicional ? this.adicionalForm.get('motivoValorAdicional')?.value : '';

        const motivoValorAdicional = this.adicionalForm.get('motivoValorAdicional')?.value;
        if (this.temValorAdicional) {
            // Supondo que motivoValorAdicional seja uma lista de strings
            this.pedido.motivoValorAdicional = motivoValorAdicional.join(',');
        } else {
            this.pedido.motivoValorAdicional = '';
        }


        //Acesrcimos pelo raio
        this.pedido.percentualAcrescimo = this.percentualAcrescimo;
        this.pedido.valorAcrescimo = this.totalAcrescimo;

        //Descontos por condpgto e vendedor
        this.pedido.percentualDesconto = this.percentualDesconto;
        this.pedido.valorDesconto = this.totalDesconto;
        this.pedido.valorDescontoVendedor = this.totalDescontoVendedor;
        this.pedido.percentualVendedor = this.vendedor.maxDiscount;

        this.pedido.valorTotal =
            this.totalQuantidade + this.totalAcrescimo - this.totalDesconto +
            this.valorTotalBomba - this.totalDescontoVendedor + this.valorAdicional;


        if (this.pedido.qtdTruckSuggested == 0) {
            this.pedido.qtdTruckSuggested = 1;
        }

        this.pedido.idVendedor = this.vendedor.id;

        console.log(this.pedido);
        this.orcamentoService.enviaOrcamento(this.pedido).subscribe((data) => {
            this.confirmaLoading = false;
            console.log(data);
            this.poNotification.success('Pedido de Venda enviado com sucesso!');

            //Zerando variaveis
            this.pedido = new Pedido();
            this.pedidoItens = [];
            this.condPgto = undefined;
            this.clienteChange = undefined;
            this.enderecoForm.reset();
            this.totalKm = 0;
            this.distance = '';

            this.produtoService.clearItemAdd();
            this.router.navigate(['/home']);

        }, (error) => {
            this.confirmaLoading = false;
            console.error('Erro ao enviar pedido:', error);
            //this.poNotification.error('Erro ao enviar pedido de venda');
        });
    }

}