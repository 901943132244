import { Component, OnInit, ViewChild } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { PoComboComponent, PoModalComponent, PoNotificationService } from "@po-ui/ng-components";
import { environment } from "../../../../environments/environment";
import { ProdutoService } from "../produto.service";
import { PedidoItem } from "../../orcamento/pedido";


@Component({
    selector: 'erpcloud-produto-add',
    templateUrl: './produto-add.component.html'
})
export class ProdutoAddComponent implements OnInit {

    @ViewChild('addProductModal') addProductModal!: PoModalComponent;
    @ViewChild('prodCombo') prodCombo!: PoComboComponent;
    readonly produtoServiceApi = environment.apiUrl + environment.versionApi + '/produto/combo';//environment.produtoServiceApi;
    productForm!: UntypedFormGroup;
    itemAdd!: PedidoItem

    constructor(private fb: UntypedFormBuilder,
        private produtoService: ProdutoService,
        private poNotification: PoNotificationService) {
        this.createProductForm();
    }

    ngOnInit(): void {

    }
    coseProductModal() {
        this.prodCombo.setInputValue('');
        this.productForm.reset();
        this.addProductModal.close();
    }
    closeModal() {
        this.addProductModal.close();
        this.prodCombo.setInputValue('');
        this.productForm.reset();
        this.addProductModal.close();
    }
    addProduct() {
        this.prodCombo.setInputValue('');
        this.addProductModal.open();
    }
    addItem() {
        this.itemAdd = new PedidoItem();
        this.itemAdd.idPedido = 0;
        this.itemAdd.codigoInterno = this.productForm.get('codInterno')?.value;
        this.itemAdd.quant = this.productForm.get('quantidade')?.value;
        this.itemAdd.valor = this.productForm.get('preco')?.value;
        this.itemAdd.valorTotal = this.productForm.get('total')?.value;
        this.itemAdd.descricao = this.productForm.get('descricao')?.value;
        this.itemAdd.perdida = '';
        this.itemAdd.estoque = '';
        this.itemAdd.qPer = 0;
        this.itemAdd.dataEmissao = new Date();
        this.itemAdd.quantB = 0;
        this.itemAdd.ori = '';
        this.itemAdd.sepa = '';
        this.itemAdd.cfop = '';
        this.itemAdd.sittri = '';
        this.itemAdd.alicms = 0;
        this.itemAdd.vlUniDol = 0;
        this.itemAdd.bIcmsSub = 0;
        this.itemAdd.baseIcms = 0;
        this.itemAdd.alIcms1 = 0;
        this.itemAdd.valorIcms = 0;
        this.itemAdd.iva = 0;
        this.itemAdd.icmsiva = 0;
        this.itemAdd.vIcmsSub = 0;
        this.itemAdd.baseIpi = 0;
        this.itemAdd.alIpi = 0;
        this.itemAdd.valorIpi = 0;
        this.itemAdd.alPis = 0;
        this.itemAdd.valorPis = 0;
        this.itemAdd.alCofins = 0;
        this.itemAdd.valCofins = 0;
        this.itemAdd.classif = '';
        this.itemAdd.libUser = '';
        this.itemAdd.libDt = new Date();
        this.itemAdd.libDesc = '';
        this.itemAdd.ordem = '';
        this.itemAdd.xped = '';
        this.itemAdd.nitemped = 0;


        this.produtoService.setItemAdd(this.itemAdd);
        this.prodCombo.setInputValue('');
        this.productForm.reset();
        this.addProductModal.close();
    }
    onChangeProduct(product: any) {

        if (!product) {
            this.productForm.reset();
        }

        console.log(product);
        this.produtoService.getProdutoById(product).subscribe((data) => {
            console.log(data);

            this.productForm.get('codInterno')?.setValue(data.codInterno);
            this.productForm.get('descricao')?.setValue(data.descricao);
            this.productForm.get('esptec1')?.setValue(data.espTec1);
            this.productForm.get('esptec2')?.setValue(data.espTec2);
            this.productForm.get('esptec3')?.setValue(data.espTec3);
            this.productForm.get('tpMaterial')?.setValue(data.tipoMaterial);
            this.productForm.get('seguimento')?.setValue(data.tipoSeguimento);
            this.productForm.get('unidade')?.setValue(data.unidade);
            this.productForm.get('preco')?.setValue(data.precoLista);
            this.productForm.get('total')?.setValue(data.precoLista);
            this.productForm.get('quantidade')?.setValue(1);

        });

    }
    createProductForm() {
        this.productForm = this.fb.group({
            codInterno: ['', Validators.compose([Validators.minLength(2), Validators.maxLength(150)])],
            descricao: ['', Validators.compose([Validators.minLength(5), Validators.maxLength(150)])],
            esptec1: [''],
            esptec2: [''],
            esptec3: [''],
            tpMaterial: ['', Validators.compose([Validators.minLength(2), Validators.maxLength(50)])],
            seguimento: [''],
            unidade: ['', Validators.compose([Validators.minLength(2), Validators.maxLength(30)])],
            quantidade: ['', Validators.compose([Validators.required, Validators.min(1), Validators.max(999)])],
            preco: ['', Validators.compose([Validators.minLength(2), Validators.maxLength(30)])],
            total: ['', Validators.compose([Validators.minLength(2), Validators.maxLength(30)])]

        });

        this.productForm.get('quantidade')?.valueChanges.subscribe(value => {
            // Update the 'preco' control value whenever 'quantidade' changes
            const quantidade = this.productForm.get('quantidade')?.value ?? 0;
            const preco = this.productForm.get('preco')?.value ?? 0;
            const data = (quantidade * preco).toFixed(2);
            this.productForm.get('total')?.setValue(data);
        });
    }
}