export class Cliente {
    id?: number; // Descomente se necessário
    razao?: string;
    fantasia?: string;
    endereco?: string;
    numero?: string;
    comple?: string;
    bairro?: string;
    cidade?: string;
    uf?: string;
    cep?: string;
    pais?: string;
    endCob?: string;
    bairroCob?: string;
    cidadeCob?: string;
    ufCob?: string;
    cepCob?: string;
    endEnt?: string;
    bairroEnt?: string;
    cidadeEnt?: string;
    ufEnt?: string;
    cepEnt?: string;
    cxEnt?: string;
    cnpj?: string;
    cpf?: string;
    insc?: string;
    fone1?: string;
    fone2?: string;
    contato?: string;
    rep?: string;
    rg?: string;
    limite?: number;
    obs?: string;
    email?: string;
    impbol: boolean = false;
    suframa?: string;
    emailNfe?: string;
    bloqueado: boolean = false;
    infoFinanceiro: string = '';
    obsNfe?: string;
    frete: boolean = false;
    obsNgo?: string;
    simples: boolean = false;
    emailBoleto: string = '';
    dataExpiraSerasa?: Date;
    dadosSerasa?: object;
}