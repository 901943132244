import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { BehaviorSubject } from "rxjs";
import { Produto } from "./produto";
import { PedidoItem } from "../orcamento/pedido";



@Injectable({
    providedIn: 'root'
})
export class ProdutoService {
    private itemAddSource = new BehaviorSubject<PedidoItem | null>(null);
    private itemRemoveSource = new BehaviorSubject<PedidoItem | null>(null);
    private clearItemsSource = new BehaviorSubject<void>(undefined);

    itemAdd$ = this.itemAddSource.asObservable();
    itemRemove$ = this.itemRemoveSource.asObservable();
    clearItems$ = this.clearItemsSource.asObservable();

    constructor(private http: HttpClient) { }

    setItemAdd(item: PedidoItem) {
        this.itemAddSource.next(item);
    }

    setItemRemove(item: PedidoItem) {
        this.itemRemoveSource.next(item);
    }

    clearItemAdd() {
        this.clearItemsSource.next();
    }

    getProdutoById(idProduto: number) {
        return this.http.get<Produto>(`${environment.apiUrl}${environment.versionApi}/produto/${idProduto}`);
    }


}