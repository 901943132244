import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { CondPgto } from "./condpgto";

@Injectable({
    providedIn: 'root'
})
export class CondPgtoService {
    constructor(private http: HttpClient) { }

    getCongPgto(idPgto: number) {
        return this.http.get<CondPgto>(`${environment.apiUrl}${environment.versionApi}${environment.pagamentoServiceApi}/${idPgto}`);
    }

}