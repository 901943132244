import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { ConsultaCnpjResponseDto } from "./serasa-cnpj-response-dto";
import { ConsultaCpfResponseDto } from "./serasa-cpf-response-dto";

@Injectable({
    providedIn: 'root'
})
export class SerasaService {

    constructor(private http: HttpClient) { }

    consultaCnpjSerasa(cnpj: string) {
        return this.http.get<ConsultaCnpjResponseDto>(`${environment.apiUrl}${environment.versionApi}/financial/get-serasa-cnpj/${cnpj}`);
    }

    consultaCpfSerasa(cpf: string) {
        return this.http.get<ConsultaCpfResponseDto>(`${environment.apiUrl}${environment.versionApi}/financial/get-serasa-cpf/${cpf}`);
    }


}