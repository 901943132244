import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Pedido } from "./pedido";
import { OrcamentoToPedidoDto } from "./orcamento-pedido-dto";
import { map } from "rxjs";
import { RaioDto } from "./raio-dto";
import { Vendedor } from "../vendedor/vendedor";

@Injectable({
    providedIn: 'root'
})
export class OrcamentoService {

    constructor(private http: HttpClient) { }

    enviaOrcamento(pedido: Pedido) {
        return this.http.post<Pedido>(`${environment.apiUrl}${environment.versionApi}/comercial/orcamento`, pedido);
    }
    getVendedorById(id: number) {
        return this.http.get<Vendedor>(`${environment.apiUrl}${environment.versionApi}/vendedor/${id}`);
    }
    convertToPedido(orcamento: OrcamentoToPedidoDto) {
        return this.http.put(`${environment.apiUrl}${environment.versionApi}/comercial/orcamento/convert-to-pedido`, orcamento);
    }
    getRaioConfigs() {
        return this.http.get<RaioDto[]>(`${environment.apiUrl}${environment.versionApi}/comercial/orcamento/get-config-raio`);
    }
    getTipoBombas() {
        //return this.http.get(`${environment.apiUrl}${environment.versionApi}/comercial/orcamento/tipo-bombas`);
        return this.http.get<any>(`${environment.apiUrl}${environment.versionApi}/comercial/orcamento/get-bombas`).pipe(
            map(response => {
                return response.items.map((item: { nome: any; descricao: any; valorMinimo: any; valorMetro: any; id: any; }) => ({
                    label: item.nome,
                    descricao: item.descricao,
                    valorMinimo: item.valorMinimo,
                    valorMetro: item.valorMetro,
                    value: item.id
                }));
            })
        );
    }
}


