<po-page-default [p-breadcrumb]="breadcrumb" p-title="WhatsApp Monitor"
    p-subtitle="Verificação de confirmações de clientes via WhatsApp">

    <div class="po-font-text-bold">Verifique as mensagens de WhatsApp enviadas pelo sistema para confirmação.</div>
    <po-divider></po-divider>

    <form [formGroup]="testeForm" (ngSubmit)="sendTemplate()">


        <div class="po-row po-mt-2po-lg-12 po-p-1">
            <po-input formControlName="numberInput" #numberInput class="po-md-12 po-lg-6" name="Informe o Número:"
                p-field-label="label" p-mask="(99) 99999-9999" p-auto-focus="true" p-label="Enviar Teste"
                p-help="Informe o Número do WhatsApp" p-icon="ph ph-whatsapp-logo" p-placeholder="Número do WhatsApp"
                p-required="true" [p-show-required]="true" p-clean>
            </po-input>
            <po-button class="po-md-12 po-mt-4 po-lg-6" p-label="Enviar Teste" (p-click)="sendTemplate()"
                p-icon="ph ph-paper-plane-tilt" [p-loading]="isLoading" [p-disabled]="!testeForm.valid">
            </po-button>
        </div>
    </form>


    <div>
        <form>
            <div class="po-row po-mb-4 po-mt-4 po-ml-1">
                <po-button class="po-md-6 po-lg-3" p-label="Atualizar" p-action="refresh" p-icon="po-icon-refresh"
                    p-type="primary" (p-click)="refreshTable()"></po-button>
            </div>
        </form>



        <div class="po-row po-mb-4 po-mt-4 shadow">
            <po-table p-container="border" [p-actions]="actions" class="po-mr-1 po-ml-1 po-md-12 po-lg-12" #wppTable
                p-striped="true" p-text-wrap="true" p-container="shadow" p-hide-columns-manager="true"
                [p-hide-table-search]="false" [p-columns]="columns" [p-service-api]="wppServiceApi">

                <ng-template p-table-column-template [p-property]="'userRecived'" let-value>
                    <span [innerText]="value" class="po-font-text-large-bold"></span>
                </ng-template>
            </po-table>
        </div>
    </div>

</po-page-default>

<!-- [p-columns]="columns"
[p-items]="items"
[p-show-more-disabled]="showMoreDisabled" -->