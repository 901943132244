import { AfterViewInit, Component, ElementRef, OnInit } from '@angular/core';
import { PoBreadcrumb, PoDynamicFormField } from '@po-ui/ng-components';
import { PoPageDynamicEditActions, PoPageDynamicEditBeforeSave, PoPageDynamicEditBeforeSaveNew, PoPageDynamicEditField } from '@po-ui/ng-templates';
import { SchemaBuilderService } from '../../../core/menu/schema-builder.service';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'erpcloud-cliente-edit',
    templateUrl: './cliente-edit.component.html',
    styleUrls: ['./cliente-edit.component.css']
})
export class ClienteEditComponent implements OnInit, AfterViewInit {

    public readonly serviceApi = environment.apiUrl + '/v1/clientes';

    public readonly breadcrumb: PoBreadcrumb = {
        items: [{ label: 'Home', link: '/' }, { label: 'Cadastro de Clientes', link: '/clientes' }, { label: 'Edição Cliente' }]
    };

    public readonly actions: PoPageDynamicEditActions = {
        save: 'clientes',
        // saveNew: '/cliente',
    };

    fields: PoPageDynamicEditField[] = []

    constructor(private el: ElementRef, private schemaBuilderService: SchemaBuilderService) { }

    ngAfterViewInit(): void {

        setTimeout(() => {
            const firstField = this.el.nativeElement.querySelector('po-input[name="nome"]');
            if (firstField) {
                firstField.focus();
            }
        });
    }

    ngOnInit(): void {
        this.schemaBuilderService.getFieldSchema("clientes").subscribe((data) => {
            this.fields = data;
        });
    }

    public readonly teste: PoPageDynamicEditBeforeSave = {
        newUrl: 'empresas'
    };

    public readonly teste2: PoPageDynamicEditBeforeSaveNew = {
        newUrl: 'empresas'
    };

}