import { NgModule } from "@angular/core";
import { DashComponent } from "./dash.component";
import { CommonModule } from "@angular/common";
import { PoModule, PoPageDefaultComponent } from "@po-ui/ng-components";
import { PoTemplatesModule } from "@po-ui/ng-templates";
import { ClienteModule } from "../clientes/cliente.module";

@NgModule({
    declarations: [DashComponent],
    exports:[DashComponent, 
        PoPageDefaultComponent],
    imports:[
        CommonModule,
        PoModule,
        PoTemplatesModule,
        ClienteModule]
})
export class DashModule { }