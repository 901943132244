import { Component, OnInit, ViewChild } from "@angular/core";
import { ClienteService } from "../cliente.service";
import { PoModalComponent, PoNotificationService } from "@po-ui/ng-components";
import { CadastroHelperService } from "../../../core/helpers/cadastro-helper.service";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { Cliente } from "../cliente";
import { EnderecoDtoV2 } from "../../../core/helpers/endereco-dto";

@Component({
    selector: 'erpcloud-cliente-add',
    templateUrl: './cliente-add.component.html'
})
export class ClienteAddComponent implements OnInit {

    isHideLoading: boolean = true;
    isLoading: boolean = false;
    cepLoading: boolean = false;
    clienteForm!: UntypedFormGroup;
    clienteToInsert!: Cliente;
    cep: string = '';
    cnpj: string = '';


    @ViewChild('addClientModal') cadastroClienteModel!: PoModalComponent;

    constructor(private fb: UntypedFormBuilder,
        private clienteService: ClienteService,
        public poNotification: PoNotificationService,
        private cadastroHelper: CadastroHelperService) {
        this.createClienteForm();
    }
    ngOnInit(): void {
        //throw new Error("Method not implemented.");
    }
    createClienteForm() {
        this.clienteForm = this.fb.group({
            razao: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(150)])],
            cliContato: ['', Validators.compose([Validators.minLength(5), Validators.maxLength(150)])],
            cliEmail: ['', Validators.compose([Validators.required, Validators.email])],
            cliTelefone: ['', Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(150)])],
            cliTelefone2: ['', Validators.compose([Validators.maxLength(150)])],
            cliAddress: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(150)])],
            cliNumber: ['', Validators.compose([Validators.required, Validators.min(1), Validators.max(99999)])],
            cliBairro: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(150)])],
            cliCompl: ['', Validators.compose([Validators.maxLength(300)])],
            cliCity: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(150)])],
            cliCnpj: ['', Validators.compose([Validators.maxLength(30)])],
            cliCpf: [''],
            cliCep: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(150)])],
            cliUf: ['', Validators.compose([Validators.required, Validators.minLength(2), Validators.maxLength(150)])]
        });
    }
    fechaCadastro() {
        this.limpaFormulario();
        this.cadastroClienteModel.close();

    }
    novoCliente() {
        this.cadastroClienteModel.open();
    }
    limpaFormulario() {
        this.clienteForm.reset();
        this.clienteToInsert = new Cliente();
    }
    consultaCep() {
        this.cepLoading = true;
        this.cadastroHelper.getEnderecoByCep(this.cep).subscribe((data: EnderecoDtoV2) => {
            console.log(data);
            this.clienteForm.get('cliAddress')?.setValue(data.street);
            this.clienteForm.get('cliCity')?.setValue(data.city);
            this.clienteForm.get('cliBairro')?.setValue(data.neighborhood);
            this.clienteForm.get('cliUf')?.setValue(data.state);

            this.clienteForm.get('cliNumber')?.setValue('');
            this.clienteForm.get('cliCompl')?.setValue('');

            //this.numberInput.focus();


        },
            (error) => {
                console.error('Erro ao buscar dados:', error);
                this.isHideLoading = true;
                this.cepLoading = false;

            });
    }
    saveCliente() {

        //this.clienteToInsert = new Cliente();

        this.clienteToInsert.razao = this.clienteForm.get('razao')?.value;
        this.clienteToInsert.cnpj = this.clienteForm.get('cliCnpj')?.value;
        this.clienteToInsert.cpf = this.clienteForm.get('cliCpf')?.value;
        this.clienteToInsert.contato = this.clienteForm.get('cliContato')?.value;
        this.clienteToInsert.email = this.clienteForm.get('cliEmail')?.value;
        this.clienteToInsert.fone1 = this.clienteForm.get('cliTelefone')?.value;
        this.clienteToInsert.fone2 = this.clienteForm.get('cliTelefone2')?.value;
        this.clienteToInsert.cep = this.clienteForm.get('cliCep')?.value;
        this.clienteToInsert.endereco = this.clienteForm.get('cliAddress')?.value;
        this.clienteToInsert.comple = this.removeExcessiveSpaces((this.clienteForm.get('cliCompl')?.value));
        this.clienteToInsert.numero = this.clienteForm.get('cliNumber')?.value;
        this.clienteToInsert.cidade = this.clienteForm.get('cliCity')?.value;
        this.clienteToInsert.bairro = this.clienteForm.get('cliBairro')?.value;
        this.clienteToInsert.uf = this.clienteForm.get('cliUf')?.value;



        this.clienteService.saveCliente(this.clienteToInsert).subscribe((data) => {
            console.log(data);
            this.poNotification.success('Cliente salvo com sucesso');
            this.limpaFormulario();
            this.cadastroClienteModel.close();

        });
    }
    removeExcessiveSpaces(text: string): string {
        if (text === null || text === undefined) {
            return text;
        }
        try {
            return text.split(' ').filter(word => word.trim() !== '').join(' ');
        } catch (error) {
            console.error('Error removing excessive spaces:', error);
            return text;
        }
    }
    checkInvalidFields() {
        const invalidFields = [];
        const controls = this.clienteForm.controls;

        for (const name in controls) {
            if (controls[name].invalid) {
                invalidFields.push(name);
            }
        }

        return invalidFields;
    }
    onCepChange(event: any) {

        this.cep = event;
        if (this.cep.length == 9) {
            //this.cepButton.focus();
        }

        console.log(this.cep);
    }
    onCnpjChange(event: any) {

        this.cnpj = event;
        if (this.cnpj.length == 14) {
            //this.cepButton.focus();
        }

        console.log(this.cnpj);
    }

    consultaCnpj() {
        this.isHideLoading = false;
        this.cnpj = this.cnpj.replace(/[./-]/g, '');

        this.cadastroHelper.getNewClient(this.cnpj).subscribe((data) => {

            console.log(data);
            this.clienteToInsert = data;
            this.clienteForm.get('razao')?.setValue(data.razao);
            this.clienteForm.get('cliCnpj')?.setValue(data.cnpj);
            this.clienteForm.get('cliCpf')?.setValue(data.cpf);
            this.clienteForm.get('cliContato')?.setValue(data.contato);
            this.clienteForm.get('cliEmail')?.setValue(data.email);
            this.clienteForm.get('cliTelefone')?.setValue(data.fone1);
            this.clienteForm.get('cliTelefone2')?.setValue(data.fone2);

            //fone2
            this.clienteForm.get('cliCep')?.setValue(data.cep);
            this.clienteForm.get('cliAddress')?.setValue(data.endereco);
            this.clienteForm.get('cliCompl')?.setValue(this.removeExcessiveSpaces(data.comple!));
            this.clienteForm.get('cliNumber')?.setValue(data.numero);
            this.clienteForm.get('cliCity')?.setValue(data.cidade);
            this.clienteForm.get('cliBairro')?.setValue(data.bairro);
            this.clienteForm.get('cliUf')?.setValue(data.uf);

            this.isHideLoading = true;
            const invalidFields = this.checkInvalidFields();
            console.log('Campos inválidos:', invalidFields);
        },
            (error) => {
                console.error('Erro ao buscar dados:', error);
                this.isHideLoading = true;
            });

    }
}