import { Component, ElementRef, ViewChild } from "@angular/core";
import { PoBreadcrumb, PoDialogService, PoModalAction, PoModalComponent, PoTableAction, PoTableColumn, PoTableColumnLabel, PoTableComponent, PoTableDetail, PoTextareaComponent, PoToasterType } from "@po-ui/ng-components";
import { environment } from "../../../../environments/environment";
import { FormBuilder, FormGroup, NgForm } from "@angular/forms";
import { ClienteService } from "../../clientes/cliente.service";
import { OrcamentoService } from "../orcamento.service";
import { OrcamentoToPedidoDto } from "../orcamento-pedido-dto";
import { SerasaComponent } from "../../serasa/serasa.component";

@Component({
    selector: 'erpcloud-orcamento-aprova',
    templateUrl: './orcamento-aprova.component.html',
    styleUrls: ['./orcamento-aprova.component.css']
})
export class OrcamentoAprovaComponent {

    @ViewChild('consultaSerasaModal') consultaSerasaModal!: SerasaComponent;
    @ViewChild('aprovaOrcamentoModal', { static: true }) aprovaOrcamentoModal!: PoModalComponent;
    @ViewChild('orcamentoTable', { static: true }) orcamentoTable!: PoTableComponent;
    @ViewChild('txtAreaInput') txtAreaInput!: ElementRef;

    form: FormGroup;
    toasterType: PoToasterType = PoToasterType.Information;
    toasterMessage: string = '';
    toasterSupoortText: string = '';
    toasterAction: () => void = () => { };
    toasterActionLabel: string = '';
    clienteLiberado: boolean = false;
    isHideLoading = true;

    readonly breadcrumb: PoBreadcrumb = {
        items: [{ label: 'Home', link: '/home' }, { label: 'Financeiro' }, { label: 'Aprovação de Orçamento' }]
    };


    readonly orcamentoServiceApi = environment.apiUrl + environment.versionApi + '/comercial/orcamento';
    readonly actions: PoTableAction[] = [
        {
            label: 'Aprovar Orçamento',
            action: this.aprovaOrcamento.bind(this),
            icon: 'ph ph-check-circle',

        },
        {
            label: 'Visualizar Orçamento',
            //action: this.test.bind(this),
            icon: 'ph ph-eye',

        },
        {
            label: 'Reprovar Orçamento',
            type: 'danger',
            //action: this.deleteMessage.bind(this),
            icon: 'ph ph-seal-warning',
            separator: true
        }
    ];
    airfareDetail: PoTableDetail = {
        columns: [
            {
                property: 'codigoInterno',
                type: 'link',
                label: 'Serviço',
            },
            {
                property: 'descricao',
                type: 'string',
                label: 'Descricao',
            },
            {
                property: 'quant',
                type: 'number',
                label: 'Quantidade',
            },
            {
                property: 'valor',
                type: 'currency',
                label: 'Valor',
                format: 'BRL'
            },
            {
                property: 'valorTotal',
                type: 'currency',
                label: 'Valor Total',
                format: 'BRL'
            }


        ], typeHeader: 'inline'
    };
    public readonly columns: Array<PoTableColumn> = [
        {
            property: 'cp',
            type: 'label',
            label: 'Status',
            width: '5%',
            labels: <Array<PoTableColumnLabel>>[
                {
                    value: 'C',
                    color: 'color-08',
                    label: 'Aguardando Aprovação',
                    textColor: 'white',
                    tooltip: 'Orçamento aguardando aprovação do financeiro'
                },
                {
                    value: 'P',
                    color: 'color-11',
                    label: 'Aprovado',
                    textColor: 'white',
                    tooltip: 'Aprovado pelo financeiro'
                },
                {
                    value: 'N',
                    color: 'color-07',
                    label: 'Negada',
                    textColor: 'white',
                    tooltip: 'Financeiro negou a aprovação'
                }
            ]
        },
        {
            property: 'id',
            width: '5%',
            label: 'Nro',
            type: 'columnTemplate',
        },
        { property: 'pedidoItens', label: 'Details', type: 'detail', detail: this.airfareDetail },
        {
            property: 'cliente.razao',
            label: 'Cliente',
            type: 'link'
        },
        {
            property: 'valorTotal',
            label: 'Valor Total',
            type: 'currency',
            format: 'BRL'
        },
        {
            property: 'dataInclusao',
            label: 'Data Inc.',
            type: 'date'
        },
        {
            property: 'condPgto.descricao',
            label: 'Condição',
            type: 'columnTemplate'
        },
        {
            property: 'vendedor.nome',
            type: 'link',
            label: 'Vendedor'
        },

    ];

    constructor(private fb: FormBuilder,
        private poDialog: PoDialogService,
        private clienteService: ClienteService,
        private orcamentoService: OrcamentoService) {

        this.form = this.fb.group({
            orcamento: [''],
            emissao: [''],
            cliente: [''],
            condicao: [''],
            obs: [''],
        });
    }
    test(item: any) {
        this.consultaSerasaModal.abrirConsulta();
    }
    finalizaAprovacao() {

        this.isHideLoading = false;
        var orcamento: OrcamentoToPedidoDto = {
            idPedido: this.form.get('orcamento')?.value,
            obsFinanceiro: this.form.get('obs')?.value,
        };

        this.orcamentoService.convertToPedido(orcamento).subscribe((data) => {
            this.aprovaOrcamentoModal.close();
            this.isHideLoading = true;
            this.orcamentoTable.applyFilters();
        }, error => {
            console.error('Erro ao buscar dados:', error);
            this.isHideLoading = true;
        });
    }
    aprovaOrcamento(orcamento: any) {

        if (orcamento.cp != 'C') {
            this.poDialog.alert({ 'title': 'Orçamento já aprovado', 'message': 'O orçamento já foi aprovado anteriormente', 'ok': () => { } });
            return;
        }

        this.isHideLoading = false;
        this.form.get('orcamento')?.setValue(orcamento.id.toString().padStart(6, '0'));
        this.form.get('emissao')?.setValue(new Date(orcamento.dataInclusao).toLocaleString());
        this.form.get('cliente')?.setValue(orcamento.cliente.razao);
        this.form.get('condicao')?.setValue(orcamento.condPgto.descricao);

        this.clienteService.getClienteById(orcamento.cliente.id).subscribe((cliente) => {
            const dataAtual = new Date();
            if (!cliente.dataExpiraSerasa) {
                this.toasterMessage = "Cliente necessita de SERASA para liberação";
                this.toasterSupoortText = "Para continuar com a aprovação do orçamento é necessário consultar o SERASA do cliente";
                this.toasterActionLabel = "Consultar Serasa";
                this.toasterType = PoToasterType.Error;

                this.toasterAction = () => {
                    this.consultaSerasaModal.cliente = cliente;
                    this.consultaSerasaModal.abrirConsulta();
                    this.aprovaOrcamentoModal.close();
                    console.log('Consultar Serasa');
                };
            }
            else if (new Date(cliente.dataExpiraSerasa) < dataAtual) {
                this.toasterMessage = "Cliente está com o SERASA vencido (" +
                    new Date(cliente.dataExpiraSerasa).toLocaleDateString() + ")";
                this.toasterActionLabel = "Consultar Serasa";
                this.toasterSupoortText = "A consulta do SERASA já realizada para esse cliente expirou, é necessário realizar uma nova consulta";

                this.toasterType = PoToasterType.Warning

                this.toasterAction = () => {
                    this.consultaSerasaModal.cliente = cliente;
                    this.consultaSerasaModal.abrirConsulta();
                    this.aprovaOrcamentoModal.close();
                    console.log('Consultar Serasa');
                };
            }
            else {
                this.toasterMessage = "Cliente liberado para compra (Serasa OK)";
                this.toasterSupoortText = "";

                this.toasterType = PoToasterType.Success;
                this.toasterActionLabel = "";
                this.toasterAction = () => { };

                this.clienteLiberado = true;

            }
            this.aprovaOrcamentoModal.open();
            console.log(orcamento);
            this.isHideLoading = true;

        }, error => {
            console.error('Erro ao buscar dados:', error);
            this.isHideLoading = true;
        });
        // this.toasterMessage = "Cliente com Serasa vencido";
        // this.toasterActionLabel = "Consultar Serasa";
        // this.toasterType = PoToasterType.Warning;

        // this.toasterAction = () => {
        //     this.aprovaOrcamentoModal.close();
        //     console.log('Consultar Serasa');
        // };


    }
    formatValue(value: number): string {
        return value.toString().padStart(5, '0');
    }

}