import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { PoModalComponent, PoTextareaComponent, PoToasterType } from '@po-ui/ng-components';
import { Cliente } from '../clientes/cliente';
import { SerasaService } from './serasa.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { PoTextareaBaseComponent } from '@po-ui/ng-components/lib/components/po-field/po-textarea/po-textarea-base.component';

@Component({
    selector: 'erpcloud-serasa',
    templateUrl: './serasa.component.html',
    styleUrls: ['./serasa.component.css']
})
export class SerasaComponent implements OnInit {

    @ViewChild('consultaSerasaModal') consultaSerasaModal!: PoModalComponent;

    @Input() cliente: Cliente = new Cliente();
    toasterType: PoToasterType = PoToasterType.Warning;
    srcImage = 'assets/serasa.png';

    form: FormGroup;
    formRaw: FormGroup;

    constructor(private serasaService: SerasaService,
        private fb: FormBuilder,) {

        this.form = this.fb.group({
            razao: [''],
            proposta: [''],
            score: [''],
            tipoRecomendacaoVenda: [''],
            valorLimiteRecomendado: [''],
            msg: ['']
        });

        this.formRaw = this.fb.group({
            rawData: ['']
        });


    }

    ngOnInit(): void {
        // Initialization code goes here
    }

    abrirConsulta() {
        this.consultaSerasaModal.open();
    }

    consultaSerasa() {

        if (this.cliente.cnpj) {
            console.log('Consultando Serasa para o CNPJ: ' + this.cliente.cnpj);
            this.serasaService.consultaCnpjSerasa(this.cliente.cnpj).subscribe((responseDto) => {
                console.log(responseDto);

                this.form.get('razao')?.setValue(responseDto.data.razaoSocial);
                this.form.get('proposta')?.setValue(responseDto.data.numeroProposta);
                this.form.get('score')?.setValue(responseDto.data.score?.serasaScore);
                this.form.get('msg')?.setValue(responseDto.data.mensagemScore);
                this.form.get('tipoRecomendacaoVenda')?.setValue(responseDto.data.tipoRecomendacaoVenda);
                this.form.get('valorLimiteRecomendado')?.setValue(responseDto.data.valorLimiteRecomendado?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }));


                this.formRaw.get('rawData')?.setValue(JSON.stringify(responseDto, null, 2));
            });

        }
        else {
            console.log('Consultando Serasa para o CPF: ' + this.cliente.cpf);

            this.serasaService.consultaCpfSerasa(this.cliente.cpf ?? '').subscribe((responseDto) => {
                console.log(responseDto);

                this.form.get('razao')?.setValue(responseDto.data.nome);
                this.form.get('proposta')?.setValue(responseDto.data.numeroProposta);
                this.form.get('score')?.setValue(responseDto.data.score?.serasaScore);
                this.form.get('msg')?.setValue(responseDto.data.mensagemScore);
                this.form.get('tipoRecomendacaoVenda')?.setValue(responseDto.data.tipoRecomendacaoVenda);
                this.form.get('valorLimiteRecomendado')?.setValue(responseDto.data.valorLimiteRecomendado?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }));


                this.formRaw.get('rawData')?.setValue(JSON.stringify(responseDto, null, 2));
            });
        }
    }

}