import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CondpgtoComponent } from './condpgto.component';
import { CondpgtoEditComponent } from './condpgto-edit/condpgto-edit.component';
import { PoModule } from '@po-ui/ng-components';
import { PoPageDynamicTableModule,PoPageDynamicEditModule } from '@po-ui/ng-templates';

@NgModule({
    declarations: [
        CondpgtoComponent,CondpgtoEditComponent
    ],
    imports: [
        CommonModule,
        PoModule,
        PoPageDynamicTableModule,
        PoPageDynamicEditModule,
    ],
    exports: [
        CondpgtoComponent,CondpgtoEditComponent,PoPageDynamicEditModule
    ]
})
export class CondpgtoModule { }