<po-page-dynamic-edit
  [p-auto-router]="true"
  p-title="Cadastrar/Editar Cliente"
  [p-actions]="actions"
  [p-breadcrumb]="breadcrumb"
  [p-fields]="fields"
 
  [p-service-api]="serviceApi"
>

</po-page-dynamic-edit>