import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClienteComponent } from './cliente.component';
import { PoModule } from '@po-ui/ng-components';
import { PoPageDynamicEditModule, PoPageDynamicTableModule } from '@po-ui/ng-templates';
import { ClienteEditComponent } from './cliente-edit/cliente-edit.component';
import { ClienteAddComponent } from './cliente-add/cliente-add.component';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
    declarations: [ClienteComponent, ClienteEditComponent, ClienteAddComponent],
    exports: [ClienteComponent, ClienteEditComponent, ClienteAddComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        PoModule,
        PoPageDynamicTableModule,
        PoPageDynamicEditModule
    ]
})
export class ClienteModule { }