export class Tenants {

    id?: string;
    name?: string;
    host?: string;
    connectionString?: string;
    email?: string;
    phone?: string;
    whatsAppPhone?: string;
    address?: string;
    city?: string;
    state?: string;
    country?: string;
    postalCode?: string;
    logo?: string;
    shortLogo?: string;
    theme?: string;
    language?: string;
    currency?: string;
    dateFormat?: string;
    timeFormat?: string;
    timeZone?: string;
    activity?: string;
    serasaApiUser?: string;
    serasaApiPass?: string;
    whatsAppDeviceToken?: string;
    cepDistance?: string;
    limitKm?: number = 0;
    totalTruck?: number = 0;
    maxDiscount: number = 0;
    msgBoasVindas?: string;

}
