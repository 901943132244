<po-page-default p-title="Dashboard" p-subtitle={{tenantInfo.msgBoasVindas}}>
  <!-- <po-divider p-label="Atalhos rápidos" p-type="dashed"></po-divider> -->
  <!-- <div class="po-row po-mb-1">
    <po-button class="po-lg-3 po-md-6 po-sm-6 po-mt-1" p-size="large" p-label="Orçamento" p-icon="po-icon po-icon-handshake" (p-click)="callerRota('/orcamento')"></po-button>
    <po-button class="po-lg-3 po-md-6 po-sm-6 po-mt-1" p-size="large" p-label="Aprov. Orçamento" p-icon="po-icon po-icon-wallet" (p-click)="callerRota('/orcamento-aprova')"></po-button>
    <po-button class="po-lg-3 po-md-6 po-sm-6 po-mt-1" p-size="large" p-label="Cad. Cliente" p-icon="ph ph-user" (p-click)="cadRapidoCli()"></po-button>
    <po-button class="po-lg-3 po-md-6 po-sm-6 po-mt-1" p-size="large" p-label="Contas a Receber" p-icon="po-icon po-icon-wallet" (p-click)="callerRota('/receber')"></po-button>
  </div> -->
  <po-divider p-label="Vendas" p-type="dashed"></po-divider>
  <div class="po-row">
    <po-widget class="po-lg-6 po-mt-2" [p-height]="200" [p-primary]="true" p-primary-label="Detalhes"
      p-title="Total de Cotações Abertas">
      <div class="po-font-subtitle po-text-center">R$ 1000.50</div>
      <div class="po-text-center">{{currentDateTime | date: 'medium' }}</div>
    </po-widget>

    <po-widget class="po-lg-3 po-mt-2" [p-height]="200" [p-primary]="true" p-primary-label="Detalhes"
      p-title="Total de Cotações - Jurídica">
      <div class="po-font-subtitle po-text-center">R$ 1000.50</div>
      <div class="po-text-center">{{currentDateTime | date: 'medium' }}</div>
    </po-widget>

    <po-widget class="po-lg-3 po-mt-2" [p-height]="200" p-primary-label="Detalhes" p-title="Total de Cotações - Física">
      <div class="po-font-subtitle po-text-center">R$ 5000</div>
      <div class="po-text-center">{{currentDateTime | date: 'medium' }}</div>
    </po-widget>
  </div>
  <po-divider p-label="Produção" p-type="dashed"></po-divider>
  <div class="po-row">
    <po-widget class="po-md-12 po-lg-4 po-mt-2" p-title="Item mais vendido no mês">
      <div class="po-font-subtitle po-text-center">CONCRETO CF45S12C</div>
      <div class="po-text-center">R$1.500,00</div>
    </po-widget>

    <po-widget class="po-md-12 po-lg-4 po-mt-2" p-title="Item mais fabricado">
      <div class="po-font-subtitle po-text-center">Concreto CF45S12C</div>
      <div class="po-text-center">30000 UN</div>
    </po-widget>

    <po-widget class="po-md-12 po-lg-4 po-mt-2" p-title="Item mais comprado">
      <div class="po-font-subtitle po-text-center">Concreto CF45S12C</div>
      <div class="po-text-center">300</div>
    </po-widget>
  </div>
</po-page-default>



<po-modal #detailsModal [p-title]="titleDetailsModal">
  <po-table [p-columns]="columnsDetails" [p-items]="itemsDetails" [p-hide-table-search]="false"> </po-table>
</po-modal>

<erpcloud-cliente-add #clienteAddComponent></erpcloud-cliente-add>