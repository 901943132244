<po-modal #addClientModal p-icon="po-icon po-icon-user-add" p-title="Novo Cliente" p-size="xl">
    <form [formGroup]="clienteForm">

        <div class="po-row">
            <div class="po-md-12 po-font-title">Inclusão de novo cliente</div>

        </div>
        <div class="po-row po-mt-3">
            <po-input #cnpjInput class="po-md-6" name="Informe o CNPJ:" p-field-label="label" p-auto-focus="true"
                p-label="CNPJ ou CPF" p-help="Informe o CNPJ ou CPF para consultar os dados" p-mask="99999999999999"
                (p-change)="onCnpjChange($event)" (p-change-model)="onCnpjChange($event)" p-icon="ph ph-map-pin"
                p-placeholder="Informe o CNPJ ou CPF" p-required="true" [p-show-required]="true" p-clean>
            </po-input>
            <po-button class="po-md-6 po-mt-4" [p-disabled]="this.cnpj.length < 11" p-label="Consultar Dados"
                p-help="Consulta CNPJ para preenchimento automático" p-icon="po-icon po-icon-search"
                [p-loading]="!isHideLoading" (p-click)="consultaCnpj()">
            </po-button>
        </div>

        <div class="po-row po-mt-2">
            <po-divider class="po-lg-12" p-label="Dados Cadastrais"></po-divider>
            <po-input class="po-lg-9" formControlName="razao" [p-show-required]="true" p-icon="ph ph-building"
                p-label="Razão/Nome" [p-show-required]="true" p-disabled="true"> </po-input>
            <po-input class="po-lg-3" formControlName="cliCnpj" p-icon="ph ph-barcode" p-label="CNPJ"
                [p-show-required]="true" p-mask="99.999.999/9999-99"> </po-input>

        </div>
        <div class="po-row">

            <po-input class="po-lg-9" formControlName="cliContato" p-icon="ph ph-user" p-required="true"
                [p-show-required]="true" p-label="Contato"></po-input>
            <po-input class="po-lg-3" formControlName="cliCpf" p-icon="ph ph-barcode" p-label="CPF">
            </po-input>
        </div>

        <po-input class="po-lg-6" formControlName="cliEmail" p-label="Email" p-icon="ph ph-at">
        </po-input>

        <po-input class="po-lg-3" formControlName="cliTelefone" p-icon="ph ph-phone" p-label="Telefone"
            [p-show-required]="true" p-mask="(99) 999999999"> </po-input>
        <po-input class="po-lg-3" formControlName="cliTelefone2" p-icon="ph ph-phone" p-label="Telefone 2"
            p-mask="(99) 999999999"> </po-input>


        <!-- endereco -->

        <po-divider class="po-md-12" p-label="Endereço"></po-divider>
        <div class="po-row">
            <po-input class="po-lg-3" formControlName="cliCep" p-icon="ICON_PIN" p-mask="99999-999" p-required="true"
                [p-show-required]="true" (p-change-model)="onCepChange($event)" (p-change)="onCepChange($event)"
                p-label="CEP"></po-input>

            <po-button class="po-lg-1 po-mt-4 po-pl-0" p-label="" p-help="" p-icon="po-icon po-icon-search"
                [p-loading]="!isHideLoading" (p-click)="consultaCep()" [p-disabled]="this.cep.length < 8">
            </po-button>

            <po-input class="po-lg-6" formControlName="cliAddress" p-icon="ICON_PIN" p-label="Endereço"> </po-input>

            <po-input #numberInput class="po-lg-2" formControlName="cliNumber" p-required="true"
                [p-show-required]="true" p-label="Número">
            </po-input>


        </div>

        <div class="po-row">

            <po-input class="po-lg-3" formControlName="cliCompl" p-label="Complemento">
            </po-input>
            <po-input class="po-lg-3" formControlName="cliBairro" p-icon="ICON_PIN" p-label="Bairro"> </po-input>
            <po-input class="po-lg-3" formControlName="cliCity" p-icon="ICON_PIN" p-label="Cidade"> </po-input>

            <po-input class="po-lg-3" formControlName="cliUf" p-label="Estado">
            </po-input>
        </div>
    </form>
    <po-modal-footer>
        <po-button p-label="Cancelar" (p-click)="fechaCadastro()"> </po-button>
        <po-button p-label="Limpar" (p-click)="limpaFormulario()"> </po-button>
        <po-button p-label="Incluir" [p-disabled]="!clienteForm.valid" p-kind="primary" (p-click)="saveCliente()">
        </po-button>
    </po-modal-footer>

</po-modal>