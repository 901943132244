import { NgModule } from "@angular/core";

import { DashModule } from "./dash/dash.module";
import { LoginModule } from "./login/login.module";
import { ClienteModule } from "./clientes/cliente.module";
import { FornecedorModule } from "./fornecedores/fornecedor.module";
import { VendedorModule } from "./vendedor/vendedor.module";
import { FuncionarioModule } from "./funcionario/funcionario.module";
import { EquipamentoModule } from "./equipamento/equipamento.module";
import { OrcamentoModule } from "./orcamento/orcamento.module";
import { CondpgtoModule } from "./condpgto/condpgto.module";
import { SectionsModule } from "./sections/sections.module";
import { BombasModule } from "./bombas/bombas.module";
import { WppModule } from "./wpp/wpp.module";
import { ProdutoModule } from "./produto/produto.module";
import { ConfigModule } from "./config/config.module";
import { ReceberModule } from "./receber/receber.module";
import { SerasaModule } from "./serasa/serasa.module";
import { CalculadoraModule } from "./calculadora/calculadora.module";

@NgModule({
    declarations: [

    ],
    imports: [
        DashModule,
        LoginModule,
        ClienteModule,
        FornecedorModule,
        OrcamentoModule,
        CondpgtoModule,
        SectionsModule,
        BombasModule,
        EquipamentoModule,
        FuncionarioModule,
        VendedorModule,
        WppModule,
        ProdutoModule,
        ConfigModule,
        ReceberModule,
        SerasaModule,
        CalculadoraModule
    ],
    exports: [

    ]
})
export class HomeModule { }