import { Component, OnInit } from '@angular/core';
import { PoBreadcrumb } from '@po-ui/ng-components';
import { PoPageDynamicEditActions, PoPageDynamicEditField } from '@po-ui/ng-templates';
import { SchemaBuilderService } from '../../../core/menu/schema-builder.service';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'erpcloud-sections-edit',
    templateUrl: './sections-edit.component.html',
    styleUrls: ['./sections-edit.component.css']
})
export class SectionsEditComponent implements OnInit {

        public readonly serviceApi = environment.apiUrl + '/v1/comercial/orcamento/sections';

        public readonly breadcrumb: PoBreadcrumb = {
            items: [{ label: 'Home', link: '/' }, { label: 'Cadastro de Sessões Operacionais', link: '/sections' }, { label: 'Edição de Sessões Operacionais' }]
        };
       
        //TODO: Implementar ações de salvar e salvar e novo
        public readonly actions: PoPageDynamicEditActions = {
            save: 'sections',
        };

        fields: PoPageDynamicEditField[] = []
        
        constructor(private schemaBuilderService: SchemaBuilderService) { }

        ngOnInit(): void {
            this.schemaBuilderService.getFieldSchema("sections").subscribe((data) => {
                this.fields = data;
            });
        }

}
