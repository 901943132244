<po-page-default p-title="Calculadora de Preços" [p-breadcrumb]="breadcrumb"
    p-subtitle="Verifique os preços gerados pelo sistema, combinando todos os fatores de acréscimos e descontos utilizados pelo sistema.">
    <hr />
    <div class="po-row">
        <span class="po-lg-12 po-md-12 po-sm-12 po-mb-2 po-font-text-uppercase">
            <span class="ph ph-calculator"></span>
            Cálculo de Preços</span>
        <po-radio-group (p-change)="selecionaBomba($event)" class="po-md-12 po-lg-12" [p-columns]="3"
            [p-required]="true" [(ngModel)]="bombaSelecionada" [p-show-required]="true"
            p-help="Escolha o tipo de bomba para o pedido" name="radioGroupBasic" p-label="Selecione o tipo de Bomba"
            [p-options]="poOptions">
        </po-radio-group>
        <po-combo #prodCombo class="po-lg-10" p-placeholder="Adicione os Produtos" name="combo"
            p-label="Selecione um Produto" p-field-label="codInterno" p-icon="ph ph-package" p-field-value="id"
            [p-required]="true" [p-show-required]="true" p-hide-columns-manager="true"
            p-placeholder="Adicione os Produtos" p-help="Adicione os produtos para o orçamento"
            [p-filter-service]="produtoServiceApi" (p-change)="onChangeProduto($event)">
        </po-combo>
        <po-number class="po-lg-2" p-help="Quantidade do Produto" [p-max]="999" [p-min]="1"
            (p-change)="onChangeQuantidade($event)" p-icon="ph ph-hash" p-label="Quantidade">
        </po-number>


        <po-input class="po-lg-6" p-mask="99999-999"
            p-help="Esse campo é relevante, pois calcula o percentual de acréscimos baseado no KM"
            p-icon="ph ph-map-pin-area" p-label="CEP da Obra" (p-change)="onCepChange($event)">
        </po-input>

        <po-combo class="po-lg-6" p-placeholder="Condição de Pagamento" name="combo" p-icon="ph ph-money"
            p-label="Selecione uma Condição de Pagamento" p-field-label="descricao" p-field-value="id"
            [p-required]="true" [p-show-required]="true" p-help="Selecione uma condição para o pagamento"
            [p-filter-service]="pagamentoServiceApi" (p-change)="onChangePagamento($event)">
        </po-combo>
        <po-switch class="po-md-12 po-lg-3" p-label="Desconto Vendedor {{ vendedor.maxDiscount }}%"
            p-icon="ph ph-percent" p-label-off="Não Aplicado" p-label-on="Aplicado"
            p-help="Desconto máximo que o vendedor pode aplicar (pode ser negado pela diretoria)"
            (p-change)="calculaDescontoVendedor($event)">
            p-icon="ph ph-percent" p-label-off="Não Aplicado" p-label-on="Aplicado">
        </po-switch>

        <div class="po-row po-mt-3">
            <po-container class="po-lg-12 custom-background">
                <span class="po-lg-12 po-md-12 po-sm-12 po-mb-2 po-font-text-uppercase">
                    <span class="ph ph-invoice"></span>
                    Totais</span>

                <div class="po-md-12 po-font-text-large-bold">
                    <po-tag p-value="+" [p-type]="poTagDanger"></po-tag>
                    Valor da Bomba: {{valorTotalBomba |
                    currency:'BRL':'symbol':'1.2-2'}}
                </div>

                <div class="po-md-12 po-font-text-large-bold">
                    <po-tag p-value="+" [p-type]="poTagDanger"></po-tag>
                    Valor dos Serviços: {{totalQuantidade |
                    currency:'BRL':'symbol':'1.2-2'}}
                </div>

                <div *ngIf="percentualAcrescimo > 0" class="po-md-12 po-font-text-large-bold">
                    <po-tag p-value="+" [p-type]="poTagDanger"> </po-tag>
                    Valor de acréscimos por
                    Km ({{ percentualAcrescimo }}%) :
                    {{ totalAcrescimo |
                    currency:'BRL':'symbol':'1.2-2'}}
                </div>
                <div *ngIf="valorAdicional > 0" class="po-md-12 po-font-text-large-bold">
                    <po-tag p-value="+" [p-type]="poTagDanger"> </po-tag>
                    Valor Adicional:
                    {{ valorAdicional |
                    currency:'BRL':'symbol':'1.2-2'}}
                </div>
                <div *ngIf="percentualDesconto > 0" class="po-md-12 po-font-text-large-bold">
                    <po-tag p-value="-" [p-type]="poTagSuccess"></po-tag>
                    Desconto Pagamento: ({{
                    percentualDesconto }}%) :
                    {{ totalDesconto |
                    currency:'BRL':'symbol':'1.2-2'}}
                </div>
                <div *ngIf="totalDescontoVendedor > 0" class="po-md-12 po-font-text-large-bold">
                    <po-tag p-value="-" [p-type]="poTagSuccess"></po-tag>
                    Desconto Vendedor: ({{
                    vendedor.maxDiscount }}%) :
                    {{ totalDescontoVendedor |
                    currency:'BRL':'symbol':'1.2-2'}}
                </div>

                <div class="po-md-12 po-font-title po-mt-2">Total do Pedido: {{
                    totalQuantidade +
                    totalAcrescimo -
                    totalDesconto -
                    totalDescontoVendedor +
                    valorTotalBomba +
                    valorAdicional |
                    currency:'BRL':'symbol':'1.2-2'}}</div>

            </po-container>
        </div>

    </div>

</po-page-default>