import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PoModule } from "@po-ui/ng-components";
import { PoTemplatesModule } from "@po-ui/ng-templates";
import { ProdutoAddComponent } from "./produto-add/produto-add.component";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
    declarations: [ProdutoAddComponent],
    exports:[ProdutoAddComponent],
    imports:[
        CommonModule,
        ReactiveFormsModule,
        PoModule,
        PoTemplatesModule]
})
export class ProdutoModule { }