import { Component, OnInit } from '@angular/core';
import { PoBreadcrumb, PoCheckboxGroupOption, PoDynamicViewField, PoNotificationService, PoPageAction, PoTableColumn } from '@po-ui/ng-components';
import { PoPageDynamicTableActions, PoPageDynamicTableCustomAction, PoPageDynamicTableCustomTableAction, PoPageDynamicTableFilters } from '@po-ui/ng-templates';
import { SchemaBuilderService } from '../../core/menu/schema-builder.service';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';
import { TenantService } from '../../core/auth/tenant.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Tenants } from '../../core/auth/tenant';
import { max } from 'rxjs';

@Component({
  selector: 'erpcloud-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.css']
})


export class ConfigComponent implements OnInit {

  isLoadind = false;
  configForm!: FormGroup;

  readonly pageActions: Array<PoPageAction> = [
    {
      label: 'Salvar Configurações',
      action: this.atualizaTenantConfig.bind(this),
      icon: 'ph ph-floppy-disk',

    },
    {
      label: 'Desfazer Alterações',
      action: this.loadTenantInfo.bind(this),
      icon: 'ph ph-warning-circle',
      //type: 'danger',
    }
  ];
  readonly breadcrumb: PoBreadcrumb = {
    items: [{ label: 'Home', link: '/home' }, { label: 'Configurações' }]
  };

  constructor(private tenantService: TenantService, private poNotificationService: PoNotificationService,
    private fb: FormBuilder) {

    this.createConfigForm();
    this.loadTenantInfo();

  }
  loadTenantInfo() {
    this.isLoadind = true;
    this.tenantService.getTenantInfo().subscribe((data) => {
      //console.log(data);
      this.configForm.get('id')?.setValue(data.id);
      this.configForm.get('name')?.setValue(data.name);
      this.configForm.get('serasaApiUser')?.setValue(data.serasaApiUser);
      this.configForm.get('serasaApiPass')?.setValue(data.serasaApiPass);
      this.configForm.get('whatsAppPhone')?.setValue(data.whatsAppPhone);
      this.configForm.get('whatsAppDeviceToken')?.setValue(data.whatsAppDeviceToken);
      this.configForm.get('api')?.setValue(environment.apiUrl);
      this.configForm.get('apiVersion')?.setValue(environment.versionApi);
      this.configForm.get('host')?.setValue(data.host);
      this.configForm.get('producao')?.setValue(environment.production);
      this.configForm.get('msgBoasVindas')?.setValue(data.msgBoasVindas);

      this.configForm.get('email')?.setValue(data.email);
      this.configForm.get('telefone')?.setValue(data.phone);
      this.configForm.get('contato')?.setValue('');
      this.configForm.get('cliAddress')?.setValue(data.address);
      this.configForm.get('cliCep')?.setValue(data.postalCode);

      this.configForm.get('maxDiscount')?.setValue(data.maxDiscount);
      this.configForm.get('limitKm')?.setValue(data.limitKm);
      this.configForm.get('totalTruck')?.setValue(data.totalTruck);
      this.configForm.get('cepDistance')?.setValue(data.cepDistance);
      this.isLoadind = false;
    });
  }
  createConfigForm() {
    this.configForm = this.fb.group({
      id: [''],
      name: [''],
      serasaApiUser: [''],
      serasaApiPass: [''],
      whatsAppPhone: [''],
      whatsAppDeviceToken: [''],
      email: [''],
      telefone: [''],
      contato: [''],
      cliAddress: [''],
      cliCep: [''],
      cliNumber: [''],
      api: [''],
      host: [''],
      apiVersion: [''],
      producao: [false],
      cepDistance: [''],
      limitKm: [''],
      totalTruck: [''],
      maxDiscount: ['0'],
      msgBoasVindas: ['']
    });
  }
  public readonly auditOptions: Array<PoCheckboxGroupOption> = [
    { value: '1', label: 'Functional menu' },
    { value: '2', label: 'Online panel' },
    { value: '3', label: 'Internet browser' },
    { value: '4', label: 'Browser details' },
    { value: '5', label: 'Transparent panel' },
    { value: '6', label: 'Browser refresh' }
  ];

  public readonly systemOptions: Array<PoCheckboxGroupOption> = [
    { value: '1', label: 'Audit updates in the data dictionary' },
    { value: '2', label: 'Audit updates in the user registry' },
    { value: '3', label: 'Audit authentication / access' },
    { value: '4', label: 'Audit rejection of access to resources' }
  ];

  atualizaTenantConfig() {

    this.isLoadind = true;
    console.log(this.configForm.value);

    this.tenantService.getTenantInfo().subscribe((tenant) => {
      //tenant.id = this.configForm.get('id')?.value;
      //tenant.name = this.configForm.get('name')?.value;
      tenant.serasaApiUser = this.configForm.get('serasaApiUser')?.value;
      tenant.serasaApiPass = this.configForm.get('serasaApiPass')?.value;
      tenant.whatsAppPhone = this.configForm.get('whatsAppPhone')?.value;
      tenant.whatsAppDeviceToken = this.configForm.get('whatsAppDeviceToken')?.value;
      tenant.email = this.configForm.get('email')?.value;
      tenant.phone = this.configForm.get('telefone')?.value;
      tenant.address = this.configForm.get('cliAddress')?.value;
      tenant.postalCode = this.configForm.get('cliCep')?.value;
      tenant.msgBoasVindas = this.configForm.get('msgBoasVindas')?.value;

      tenant.maxDiscount = this.configForm.get('maxDiscount')?.value;
      tenant.limitKm = this.configForm.get('limitKm')?.value;
      tenant.totalTruck = this.configForm.get('totalTruck')?.value;
      tenant.cepDistance = this.configForm.get('cepDistance')?.value;

      console.log(tenant);

      this.tenantService.updateTenantInfo(tenant).subscribe((data) => {

        this.isLoadind = false;
        this.poNotificationService.success('Configuração atualizada com sucesso!');
        console.log(data);
      }, (error) => {
        this.isLoadind = false;
        //this.poNotificationService.error('Erro ao atualizar a configuração!'); 
        console.log(error);
      });
    });
  }

  ngOnInit(): void {

  }
}