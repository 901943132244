import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Pedido } from "./pedido";
import { OrcamentoToPedidoDto } from "./orcamento-pedido-dto";
import { map } from "rxjs";
import { RaioDto } from "./raio-dto";
import { Vendedor } from "../vendedor/vendedor";
import { ListDataDto } from "../../core/common/list-data-dto";
import { PoTableColumn, PoTableColumnLabel, PoTableDetail } from "@po-ui/ng-components";

@Injectable({
    providedIn: 'root'
})
export class OrcamentoService {


    orcamentoDetail: PoTableDetail = {
        columns: [
            {
                property: 'codigoInterno',
                type: 'link',
                label: 'Serviço',
            },
            {
                property: 'descricao',
                type: 'string',
                label: 'Descricao',
            },
            {
                property: 'quant',
                type: 'number',
                label: 'Quantidade',
            },
            {
                property: 'valor',
                type: 'currency',
                label: 'Valor',
                format: 'BRL'
            },
            {
                property: 'valorTotal',
                type: 'currency',
                label: 'Valor Total',
                format: 'BRL'
            }


        ], typeHeader: 'inline'
    };

    public readonly orcamentoColumns: Array<PoTableColumn> = [
        {
            property: 'cp',
            type: 'label',
            label: 'Status',
            width: '5%',
            labels: <Array<PoTableColumnLabel>>[
                {
                    value: 'C',
                    color: 'color-01',
                    label: 'Não Enviado',
                    textColor: 'white',
                    tooltip: 'Aguardando envio para aprovação comercial'
                },
                {
                    value: 'F',
                    color: 'color-09',
                    label: 'Pendente Comercial',
                    textColor: 'white',
                    tooltip: 'Aguardando aprovação do comercial'
                },
                {
                    value: 'S',
                    color: 'color-08',
                    label: 'Pendente Financeiro',
                    textColor: 'white',
                    tooltip: 'Aguardando aprovação do financeiro'
                },
                {
                    value: 'P',
                    color: 'color-05',
                    label: 'Pendente Roteiro',
                    textColor: 'white',
                    tooltip: 'Aguardando definição de roteiro'
                },
                {
                    value: 'B',
                    color: 'color-09',
                    label: 'Aguardando Baixa Financeira',
                    textColor: 'white',
                    tooltip: 'Aguardando Baixa Financeira'
                },
                {
                    value: 'L',
                    color: 'color-04',
                    label: 'Pendente Carregamento',
                    textColor: 'white',
                    tooltip: 'Aguardando carregamento'
                },
                {
                    value: 'N',
                    color: 'color-06',
                    label: 'Pendente Fiscal',
                    textColor: 'white',
                    tooltip: 'Aguardando emissão de documento fiscal'
                },
                {
                    value: 'F',
                    color: 'color-10',
                    label: 'Finalizado',
                    textColor: 'white',
                    tooltip: 'Pedido Finalizado'
                },
                {
                    value: 'R',
                    color: 'color-07',
                    label: 'Reprovado',
                    textColor: 'white',
                    tooltip: 'Pedido Reprovado'
                }
            ]
        },
        {
            property: 'id',
            width: '5%',
            label: 'Nro',
            type: 'columnTemplate',
        },
        {
            property: 'dataInclusao',
            label: 'Data Inc.',
            type: 'date'
        },
        { property: 'pedidoItens', label: 'Details', type: 'detail', detail: this.orcamentoDetail },
        {
            property: 'cliente.razao',
            label: 'Cliente',
            type: 'link'
        },
        {
            property: 'valorProdutos',
            label: 'Valor Produtos',
            type: 'currency',
            format: 'BRL'
        },
        {
            property: 'valorBomba',
            label: 'Valor Bomba',
            type: 'currency',
            visible: false,
            format: 'BRL'
        },
        {
            property: 'valorAcrescimo',
            label: 'Adic. (KM)',
            type: 'currency',
            visible: false,
            format: 'BRL'
        },
        {
            property: 'valorAdicional',
            label: 'Adicional',
            type: 'currency',
            visible: false,
            format: 'BRL'
        },
        {
            property: 'valorDesconto',
            label: 'Desc. Pgto',
            type: 'currency',
            visible: false,
            format: 'BRL'
        },
        {
            property: 'valorDescontoVendedor',
            label: 'Desc. Vendedor',
            type: 'currency',
            visible: false,
            format: 'BRL'
        },
        {
            property: 'valorTotal',
            label: 'Valor Total',
            type: 'currency',
            format: 'BRL'
        },
        {
            property: 'condPgto.descricao',
            label: 'Condição',
            type: 'columnTemplate'
        },
        {
            property: 'vendedor.nome',
            type: 'link',
            visible: false,
            label: 'Vendedor'
        },

    ];

    constructor(private http: HttpClient) { }
    getOrcamentosVendedor(page: number, pageSize: number) {
        return this.http.get<ListDataDto>(`${environment.apiUrl}${environment.versionApi}/comercial/orcamento/vendedor?page=${page}&pageSize=${pageSize}`);
    }
    getOrcamentosAprovaFinanceiro(page: number, pageSize: number) {
        return this.http.get<ListDataDto>(`${environment.apiUrl}${environment.versionApi}/comercial/orcamento/financeiro?page=${page}&pageSize=${pageSize}`);
    }
    getOrcamentosAprovaComercial(page: number, pageSize: number) {
        return this.http.get<ListDataDto>(`${environment.apiUrl}${environment.versionApi}/comercial/orcamento/comercial?page=${page}&pageSize=${pageSize}`);
    }
    deleteOrcamento(idOrcamento: number) {
        return this.http.delete(`${environment.apiUrl}${environment.versionApi}/comercial/orcamento/${idOrcamento}`);
    }
    visualizaOrcamento(idOrcamento: number) {
        return this.http.get<string>(`${environment.apiUrl}${environment.versionApi}/comercial/reports/orcamento/${idOrcamento}`);
    }
    enviaOrcamento(pedido: Pedido) {
        return this.http.post<Pedido>(`${environment.apiUrl}${environment.versionApi}/comercial/orcamento`, pedido);
    }
    enviaOrcamentoComercial(orcamento: OrcamentoToPedidoDto) {
        return this.http.put<Pedido>(`${environment.apiUrl}${environment.versionApi}/comercial/orcamento/envia-comercial`, orcamento);
    }
    getVendedorById(id: number) {
        return this.http.get<Vendedor>(`${environment.apiUrl}${environment.versionApi}/vendedor/${id}`);
    }
    convertToPedido(orcamento: OrcamentoToPedidoDto) {
        return this.http.put(`${environment.apiUrl}${environment.versionApi}/comercial/orcamento/convert-to-pedido`, orcamento);
    }
    enviaFinanceiro(orcamento: OrcamentoToPedidoDto) {
        return this.http.put(`${environment.apiUrl}${environment.versionApi}/comercial/orcamento/envia-financeiro`, orcamento);
    }
    getRaioConfigs() {
        return this.http.get<RaioDto[]>(`${environment.apiUrl}${environment.versionApi}/comercial/orcamento/get-config-raio`);
    }
    getTipoBombas() {
        //return this.http.get(`${environment.apiUrl}${environment.versionApi}/comercial/orcamento/tipo-bombas`);
        return this.http.get<any>(`${environment.apiUrl}${environment.versionApi}/comercial/orcamento/get-bombas`).pipe(
            map(response => {
                return response.items.map((item: { nome: any; descricao: any; valorMinimo: any; valorMetro: any; id: any; }) => ({
                    label: item.nome,
                    descricao: item.descricao,
                    valorMinimo: item.valorMinimo,
                    valorMetro: item.valorMetro,
                    value: item.id
                }));
            })
        );
    }
}


