import { Component, OnInit, ViewChild } from "@angular/core";
import { PoBreadcrumb, PoInputComponent, PoTableAction, PoTableColumn, PoTableColumnLabel, PoTableComponent } from "@po-ui/ng-components";
import { environment } from "../../../environments/environment";
import { WppService } from "./wpp.service";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";

@Component({
    selector: 'erpcloud-wpp',
    templateUrl: './wpp.component.html',
    styleUrls: ['./wpp.component.css']
})
export class WppComponent implements OnInit {

    @ViewChild('wppTable') wppTable!: PoTableComponent;
    @ViewChild('numberInput') numberInput!: PoInputComponent;

    readonly wppServiceApi = environment.apiUrl + environment.versionApi + '/whatsapp';
    extraInformation: any;
    testeForm!: UntypedFormGroup;
    //items!: Array<any>;
    showMoreDisabled: boolean = false;
    title: any;
    isLoading: boolean = false;

    readonly breadcrumb: PoBreadcrumb = {
        items: [{ label: 'Home', link: '/home' }, { label: 'Cadastro' }, { label: 'Cadastro de Clientes' }]
    };

    readonly actions: PoTableAction[] = [
        {
            label: 'Reenviar Mensagem',
            action: this.retryMessage.bind(this),
            icon: 'ph ph-paper-plane-tilt',

        },
        {
            label: 'Excluir Envio',
            type: 'danger',
            action: this.deleteMessage.bind(this),
            icon: 'ph ph-trash',
            separator: true
        }
    ];


    public readonly columns: Array<PoTableColumn> = [
        {
            property: 'status',
            type: 'label',
            label: 'Status',
            width: '5%',
            labels: <Array<PoTableColumnLabel>>[
                {
                    value: 'C',
                    color: 'color-11',
                    label: 'Confirmado',
                    textColor: 'white',
                    tooltip: 'Confirmação recebida'
                },
                {
                    value: 'P',
                    color: 'color-08',
                    label: 'Aguardando',
                    textColor: 'white',
                    tooltip: 'Aguardando Confirmação do Cliente'
                },
                {
                    value: 'N',
                    color: 'color-07',
                    label: 'Negada',
                    textColor: 'white',
                    tooltip: 'Cliente negou a confirmação'
                }
            ]
        },
        {
            property: 'numberSend',
            label: 'Whatsapp Enviado',
            type: 'link'
        },
        {
            property: 'userRecived',
            label: 'Nome Usuário',
            type: 'columnTemplate'
        },
        {
            property: 'textSend',
            label: 'Mensagem Enviada',
            type: 'string'
        },
        {
            property: 'textRecived',
            label: 'Mensagem Recebida',
            type: 'string'
        },

    ];
    constructor(private wppService: WppService, private fb: UntypedFormBuilder,) {

    }

    createTesteForm() {
        this.testeForm = this.fb.group({
            numberInput: ['', Validators.compose([Validators.required, Validators.minLength(11), Validators.maxLength(11)])],
        });
    }

    sendTemplate() {
        this.isLoading = true;
        this.wppService.sendMessageTemplate('55' + this.numberInput.modelLastUpdate).subscribe((response) => {
            console.log(response);
            this.wppTable.applyFilters();
            this.isLoading = false;
        });
    }

    refreshTable() {
        //this.clienteAddComponent.novoCliente();
        this.wppTable.applyFilters();
    }
    deleteMessage(message: any) {
        console.log(message);
        this.wppService.deleteMessage(message.id).subscribe((response) => {
            this.wppTable.applyFilters();
        });
    }
    retryMessage(message: any) {
        this.wppService.retryMessage(message.id).subscribe((response) => {
            this.wppTable.applyFilters();
        });
    }
    ngOnInit(): void {

        this.createTesteForm();

    }

}