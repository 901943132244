import { Component, OnInit } from '@angular/core';
import { PoBreadcrumb, PoDynamicViewField, PoTableColumn } from '@po-ui/ng-components';
import { PoPageDynamicTableActions, PoPageDynamicTableCustomAction, PoPageDynamicTableCustomTableAction, PoPageDynamicTableFilters } from '@po-ui/ng-templates';
import { SchemaBuilderService } from '../../core/menu/schema-builder.service';
import { environment } from '../../../environments/environment';
import { ActivatedRoute } from '@angular/router';

@Component({
    selector: 'erpcloud-sections',
    templateUrl: './sections.component.html',
    styleUrls: ['./sections.component.css']
})
export class SectionsComponent implements OnInit {


    readonly serviceApi = environment.apiUrl + '/v1/comercial/orcamento/sections';
    loading = false;

    readonly breadcrumb: PoBreadcrumb = {
        items: [{ label: 'Home', link: '/home' }, { label: 'Cadastro' }, { label: 'Cadastro de Sessões Operacionais' }]
    };
    readonly actions: PoPageDynamicTableActions = {

        new: '/sections-edit/',
        edit: '/sections-edit/:id',
        remove: true,

    };

    fields: Array<PoPageDynamicTableFilters> = [];
    detailFields: Array<PoDynamicViewField> = [];
    pageCustomActions: Array<PoPageDynamicTableCustomAction> = [
        // { label: 'Export', selectable:true, action: this.onClickPecaDetail.bind(this),  icon: 'po-icon-refresh' },
        // { label: 'Print', action: this.onClickPecaDetail.bind(this) }
    ];

    tableCustomActions: Array<PoPageDynamicTableCustomTableAction> = [];

    constructor(private schemaBuilderService: SchemaBuilderService, private route: ActivatedRoute) {
        this.schemaBuilderService = schemaBuilderService;
    }

    ngOnInit(): void {
        this.fields = this.route.snapshot.data['fields'];
    }

}