import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SerasaComponent } from './serasa.component';
import { ReactiveFormsModule } from '@angular/forms';
import { PoModule } from '@po-ui/ng-components';
import { PoTemplatesModule } from '@po-ui/ng-templates';

@NgModule({
    declarations: [SerasaComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        PoModule,
        PoTemplatesModule,
    ],
    exports: [SerasaComponent]
})
export class SerasaModule { }