export class CondPgto {
    id?: number;
    descricao?: string;
    baseNf?: string;
    diaDecendFixo?: string;
    diaSemanaFixo?: number;
    qtdParcelas?: number;
    parc1?: number;
    parc2?: number;
    parc3?: number;
    parc4?: number;
    parc5?: number;
    parc6?: number;
    parc7?: number;
    parc8?: number;
    parc9?: number;
    parc10?: number;
    parc11?: number;
    parc12?: number;
    desconto?: number;
}

