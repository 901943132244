export class Pedido {
    id?: number;
    idCliente?: number;
    idVendedor?: number;
    idSection?: number;
    idBomba?: number;
    idPgto?: number;
    dataInclusao?: Date;
    valorTotal?: number;
    perda?: number;
    coleta?: string;
    volume?: string;
    peso?: number;
    tran?: string;
    contato?: string;
    obs1?: string;
    nota?: string;
    dataN?: Date;
    desconto?: number;
    estoque?: string;
    comprador?: string;
    usu?: string;
    usuFinan?: string;
    dataLiberaFinan?: Date;
    confe?: string;
    sepa?: string;
    netoe?: string;
    tipo?: string;
    datae?: Date;
    usonota?: string;
    cp?: string;
    comiRepr?: number;
    imp: boolean = false;
    obsSepa?: string;
    descVol?: string;
    xped?: string;
    dataColeta?: Date;
    numeroColeta?: string;
    atendenteColeta?: string;
    solicitanteColeta?: string;
    expedicao: boolean = false;
    obsNf?: string;
    freteCliente?: boolean;
    obsNgo?: string;
    coletado: boolean = false;
    volumePermitido?: number;
    dataSolicitacao?: Date;
    dataBaixaColeta?: Date;
    prevEntrega?: Date;
    prevFaturamento?: string;
    dtExpedicao?: Date;
    dataProrroga?: Date;
    pesol: number = 0;
    pesob: number = 0;
    needColeta: boolean = false;
    obsColeta?: string;
    obsFinan?: string;
    qtdTruckSuggested?: number;
    qtdBomb?: number;
    qtdKm?: number;
    percentualAcrescimo?: number;
    valorAcrescimo?: number;
    percentualDesconto?: number;
    valorDesconto?: number;
    valorBomba?: number;
    percentualVendedor?: number;
    valorDescontoVendedor?: number;
    valorAdicional?: number;
    motivoValorAdicional?: string;
    valorProdutos?: number;
    pedidoItens?: PedidoItem[];
}

export class PedidoItem {
    idPedido?: number;
    peca?: string;
    codigoInterno?: string;
    quant?: number;
    valor?: number;
    valorTotal?: number;
    descricao?: string;
    perdida?: string;
    estoque?: string;
    qPer?: number;
    dataEmissao?: Date;
    quantB?: number;
    ori?: string;
    sepa?: string;
    cfop?: string;
    sittri?: string;
    alicms?: number;
    vlUniDol?: number;
    bIcmsSub?: number;
    baseIcms?: number;
    alIcms1?: number;
    valorIcms?: number;
    iva?: number;
    icmsiva?: number;
    vIcmsSub?: number;
    baseIpi?: number;
    alIpi?: number;
    valorIpi?: number;
    alPis?: number;
    valorPis?: number;
    alCofins?: number;
    valCofins?: number;
    classif?: string;
    libUser?: string;
    libDt?: Date;
    libDesc?: string;
    ordem?: string;
    xped?: string;
    nitemped?: number;
    pedido?: Pedido;
}

