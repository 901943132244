import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PoModule } from '@po-ui/ng-components';
import { PoPageDynamicEditModule, PoPageDynamicTableModule, PoTemplatesModule } from '@po-ui/ng-templates';
import { WppComponent } from './wpp.component';
import { ReactiveFormsModule } from '@angular/forms';
import { ClienteModule } from "../clientes/cliente.module";

@NgModule({
    declarations: [WppComponent],
    exports: [WppComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        PoModule,
        PoTemplatesModule,
    ]
})
export class WppModule { }