import { Component } from '@angular/core';
import { PoBreadcrumb, PoPageAction, PoTagType } from '@po-ui/ng-components';
import { environment } from '../../../environments/environment';
import { RaioDto } from '../orcamento/raio-dto';
import { PedidoItem } from '../orcamento/pedido';
import { CondPgto } from '../condpgto/condpgto';
import { CondPgtoService } from '../condpgto/condpgto.service';
import { ProdutoService } from '../produto/produto.service';
import { Produto } from '../produto/produto';
import { TenantService } from '../../core/auth/tenant.service';
import { Tenants } from '../../core/auth/tenant';
import { OrcamentoService } from '../orcamento/orcamento.service';
import { CadastroHelperService } from '../../core/helpers/cadastro-helper.service';
import { DistanceRequestDto } from '../../core/helpers/distance-request-dto';
import { DistanceResponseDto } from '../../core/helpers/distance-response-dto';
import { SimpleDistanceResponseDto } from '../../core/helpers/simple-distance-request-dto';


@Component({
    selector: 'erpcloud-calculadora',
    templateUrl: './calculadora.component.html',
    styleUrls: ['./calculadora.component.css']
})
export class CalculadoraComponent {

    isLoadind = false;
    readonly produtoServiceApi = environment.apiUrl + environment.versionApi + '/produto/combo';
    readonly pagamentoServiceApi = environment.apiUrl + environment.versionApi + environment.pagamentoServiceApi;
    //configForm!: FormGroup;
    readonly breadcrumb: PoBreadcrumb = {
        items: [{ label: 'Home', link: '/home' }, { label: 'Orçamentos' }, { label: 'Calculadora de Preços' }]
    };

    readonly pageActions: Array<PoPageAction> = [
        {
            label: 'Salvar Configurações',
            //action: this.atualizaTenantConfig.bind(this),
            icon: 'ph ph-floppy-disk',

        },
        {
            label: 'Desfazer Alterações',
            //action: this.loadTenantInfo.bind(this),
            icon: 'ph ph-warning-circle',
            //type: 'danger',
        }
    ];

    percentualAcrescimo: number = 0;
    totalAcrescimo: number = 0;
    totalDesconto: number = 0;
    percentualDesconto: number = 0;
    totalDescontoPedido: number = 0;
    raioConfigs: RaioDto[] = [];
    poTagDanger: PoTagType = PoTagType.Danger;
    poTagSuccess: PoTagType = PoTagType.Success;
    itemSelecionado: Produto = new Produto();
    pagamentoSelecionado: CondPgto = new CondPgto();
    totalKm: number = 0;
    quantidade: number = 1;
    tenantInfo: Tenants = new Tenants();
    cep: string = '';


    constructor(private condPgtoService: CondPgtoService,
        private tenantService: TenantService,
        private cadastroHelper: CadastroHelperService,
        private orcamentoService: OrcamentoService,
        private produtoService: ProdutoService) {

        this.tenantService.getTenantInfo().subscribe(tenant => {
            this.tenantInfo = tenant;
        });

        // this.orcamentoService.getTipoBombas().subscribe(data => {
        //     this.poOptions = data;
        //     this.bombaSelecionada = 1;
        // });

        this.orcamentoService.getRaioConfigs().subscribe(data => {
            this.raioConfigs = data;
            console.log(this.raioConfigs);
        });

    }

    ngOnInit() {
        // Lógica de inicialização do componente
    }

    get totalQuantidade(): number {
        return Number(this.itemSelecionado.precoLista) * this.quantidade;
    }
    onChangeProduto(produto: number) {

        this.produtoService.getProdutoById(produto).subscribe((data) => {
            this.itemSelecionado = data;
            this.calculaAcrescimo();
            this.calculaDesconto();
        });

    }
    onChangeQuantidade(quantidade: number) {
        this.quantidade = Number(quantidade);
        this.calculaAcrescimo();
        this.calculaDesconto();
    }
    onChangePagamento(pgto: number) {

        this.condPgtoService.getCongPgto(pgto).subscribe((data) => {
            this.pagamentoSelecionado = data;
            this.calculaDesconto();
        });

    }
    calculaDesconto() {
        this.percentualDesconto = this.pagamentoSelecionado?.desconto ?? 0;
        this.totalDesconto = (this.totalQuantidade + this.totalAcrescimo) * (this.percentualDesconto / 100);
    }
    onCepChange(event: any) {

        this.cep = event;
        console.log(this.cep);
        if (this.cep.length == 9) {
            console.log(this.cep);
            const requestDto = new DistanceRequestDto();
            requestDto.mode = 'driving';
            requestDto.origin = this.tenantInfo.cepDistance;
            requestDto.destination = this.cep;


            this.cadastroHelper.getDistanceBetweenCepsGoogle(requestDto).subscribe((data: SimpleDistanceResponseDto) => {
                console.log(data);
                this.totalKm = Number(data.distanceValue ?? 0);

                console.log(this.totalKm);
                this.calculaAcrescimo();
                this.calculaDesconto();
            });

        }


    }
    calculaAcrescimo() {
        this.percentualAcrescimo = this.getAcrecimoRaio();
        this.totalAcrescimo = this.totalQuantidade * (this.percentualAcrescimo / 100);
        //(totalAcrescimo / 100) * totalQuantidade
        console.log('Acrescimo:' + this.totalAcrescimo);
    }
    getAcrecimoRaio(): number {

        let acrescimo = 0;

        for (let raio of this.raioConfigs) {
            if ((this.totalKm / 1000) < raio.raio) {
                return raio.acrescimo;
            }
        }

        return acrescimo;
    }


}