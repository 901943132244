import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { PoMenuItem } from "@po-ui/ng-components";
import { environment } from "../../../environments/environment";
import { Subject } from "rxjs";

const API = environment.apiUrl;

@Injectable({providedIn:'root'})
export class MenuService {

    private triggerFunctionSubject = new Subject<any>();
    triggerFunctionObservable = this.triggerFunctionSubject.asObservable();
    
    constructor(private http: HttpClient) { }

    triggerFunction() {
        this.triggerFunctionSubject.next(true);
    }
    getMenuSchema()
    {
        return this.http.get<PoMenuItem[]>(API + "/v1/schema-builder/get-menu-schema");
    }

}