<po-page-default [p-breadcrumb]="breadcrumb" p-title="Aprovação Financeira - Orçamentos" [p-actions]="pageActions"
    p-subtitle="Orcamentos pendentes de aprovação enviados no sistema">
    <div class="po-font-text-bold po-text-color-neutral-dark-40">Orçamentos já enviados no sistema.</div>
    <po-divider></po-divider>
    <div class="po-row po-mb-4 po-mt-4 shadow">
        <div>
            <po-loading-overlay [hidden]="isHideLoading"></po-loading-overlay>
        </div>
        <po-table #orcamentoTable p-container="border" [p-actions]="actions" class="po-mr-1 po-ml-1 po-lg-12" #wppTable
            p-striped="true" p-text-wrap="true" p-container="shadow" p-hide-columns-manager="true"
            (p-show-more)="loadMore()" [p-show-more-disabled]="!moreItens" [p-items]="this.tableItens"
            [p-hide-table-search]="false" [p-columns]="columns">

            <ng-template p-table-column-template [p-property]="'condPgto.descricao'" let-value>
                <span [innerText]="value" class="po-font-text-large-bold"></span>
            </ng-template>
            <ng-template p-table-column-template [p-property]="'id'" let-value>
                <span [innerText]="value | padLeft:6" class="po-font-text-large-bold"></span>
                <!-- <div class="badge">{{ value | padLeft:6 }}</div> -->
            </ng-template>
        </po-table>
    </div>

</po-page-default>

<po-modal #aprovaOrcamentoModal p-title="Aprovação de Orçamento (Financeiro)" p-icon="ph ph-invoice"
    (p-close)="aprovaOrcamentoModal.close()">
    <div class="po-row">
        <po-toaster class="po-md-12" [p-message]="toasterMessage" [p-action]="toasterAction"
            [p-support-message]="toasterSupoortText" [p-action-label]="toasterActionLabel" [p-type]="toasterType"
            p-show-close="false">

        </po-toaster>
    </div>
    <form [formGroup]="form">
        <div class="po-row po-mt-2">
            <po-input class="po-md-3" formControlName="orcamento" p-label="Cód:" p-disabled="true">
            </po-input>
            <po-input class="po-md-9" formControlName="emissao" p-label="Data de Emissão:" p-disabled="true">
            </po-input>
        </div>
        <div class="po-row">
            <po-input class="po-md-12" formControlName="cliente" p-label="Cliente" p-disabled="true">
            </po-input>
            <po-input class="po-md-12" formControlName="condicao" p-label="Condição de Pagamento" p-disabled="true">
            </po-input>
        </div>
        <div class="po-row">
            <po-textarea #txtAreaInput class="po-md-12" formControlName="obs"
                p-help="Informe observações relevantes que devam ser consideradas no processo de aprovação do cliente"
                name="orderDetail" p-label="Observação Financeiro:" [p-rows]=5 p-auto-focus="true">
            </po-textarea>
        </div>
    </form>

    <po-modal-footer [p-disabled-align]="false">
        <po-button p-label="Cancelar" (p-click)="aprovaOrcamentoModal.close()"> </po-button>
        <po-button p-kind="primary" (p-click)="finalizaAprovacao()" p-label="Aprovar" [p-disabled]="!clienteLiberado">
        </po-button>
    </po-modal-footer>
</po-modal>


<erpcloud-serasa #consultaSerasaModal>
</erpcloud-serasa>