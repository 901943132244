import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { UserService } from "../user/user.service";
import { environment } from "../../../environments/environment";
import { Tenants } from "./tenant";

@Injectable({ providedIn: 'root' })
export class TenantService {

    //readonly serviceApi = environment.apiUrl + '/v1/security/get-tenant-info';
    //public tenant_id: string = '';
    readonly serviceApi = environment.apiUrl;

    constructor(private http: HttpClient) { }

    getTenantInfo() {
        return this.http.get<Tenants>(`${this.serviceApi}` + '/v1/security/get-tenant-info');
    }
    updateTenantInfo(tenant: Tenants) {
        return this.http.put(`${this.serviceApi}` + '/v1/security/update-tenant-info', tenant);
    }
}