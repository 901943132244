import { Component, OnInit } from '@angular/core';
import { PoBreadcrumb } from '@po-ui/ng-components';
import { PoPageDynamicEditActions, PoPageDynamicEditField } from '@po-ui/ng-templates';
import { SchemaBuilderService } from '../../../core/menu/schema-builder.service';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'erpcloud-condpgtos-edit',
    templateUrl: './condpgto-edit.component.html',
    styleUrls: ['./condpgto-edit.component.css']
})
export class CondpgtoEditComponent implements OnInit {

        public readonly serviceApi = environment.apiUrl + '/v1/condpgto';

        public readonly breadcrumb: PoBreadcrumb = {
            items: [{ label: 'Home', link: '/' }, { label: 'Cadastro de Condição de pagamento', link: '/condpgtos' }, { label: 'Edição Condição de pagamento' }]
        };
       
        //TODO: Implementar ações de salvar e salvar e novo
        public readonly actions: PoPageDynamicEditActions = {
            save: 'condpgtos',
        };

        fields: PoPageDynamicEditField[] = []
        
        constructor(private schemaBuilderService: SchemaBuilderService) { }

        ngOnInit(): void {
            this.schemaBuilderService.getFieldSchema("condpgtos").subscribe((data) => {
                this.fields = data;
            });
        }

}
