import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FornecedorComponent } from './fornecedor.component';
import { FornecedorEditComponent } from './fornecedores-edit/fornecedor-edit.component';
import { PoModule } from '@po-ui/ng-components';
import { PoPageDynamicTableModule,PoPageDynamicEditModule } from '@po-ui/ng-templates';

@NgModule({
    declarations: [
        FornecedorComponent,FornecedorEditComponent
    ],
    imports: [
        CommonModule,
        PoModule,
        PoPageDynamicTableModule,
        PoPageDynamicEditModule,
    ],
    exports: [
        FornecedorComponent,FornecedorEditComponent,PoPageDynamicEditModule
    ]
})
export class FornecedorModule { }