<po-modal #addProductModal p-icon="po-icon po-icon-user-add" p-title="Inclusão de Produto" p-size="xl">
    <form [formGroup]="productForm">
        <div class="po-row">
            <div class="po-md-12 po-font-title">Incluir Produto/Serviço</div>

        </div>
        <div class="po-row">
            <!-- <po-lookup class="po-lg-12" p-placeholder="Adicione os Produtos" name="combo" p-label="Selecione um Produto"
                p-field-label="codInterno" p-field-value="id" [p-required]="true" [p-show-required]="true"
                (p-change)="onChangeProduct($event)" p-hide-columns-manager="true" p-placeholder="Adicione os Produtos"
                p-help="Adicione os produtos para o orçamento" [p-filter-service]="produtoServiceApi">
            </po-lookup> -->
            <po-combo #prodCombo class="po-lg-12" p-placeholder="Adicione os Produtos" name="combo"
                p-label="Selecione um Produto" p-field-label="codInterno" p-icon="ph ph-package" p-field-value="id"
                [p-required]="true" [p-show-required]="true" (p-change)="onChangeProduct($event)"
                p-hide-columns-manager="true" p-placeholder="Adicione os Produtos"
                p-help="Adicione os produtos para o orçamento" [p-filter-service]="produtoServiceApi">
            </po-combo>

        </div>


        <div class="po-row po-mt-3">
            <po-divider class="po-lg-12" p-label="Dados Gerais"></po-divider>
            <po-input #codInternoInput formControlName="codInterno" class="po-md-3" name="codInternoInput"
                p-field-label="label" p-auto-focus="true" p-label="Código do Produto" p-disabled="true"
                p-icon="ph ph-package">
            </po-input>
            <po-input #descricao class="po-md-9" formControlName="descricao" name="descricao" p-field-label="label"
                p-auto-focus="true" p-label="Descrição do Produto" p-disabled="true" p-icon="ph ph-article">
            </po-input>
        </div>
        <div class="po-row">
            <po-input #esptec1 class="po-md-4" formControlName="esptec1" name="esptec1" p-field-label="label"
                p-icon="ph ph-flask" p-auto-focus="true" p-label="Especificação Técnica 1" p-disabled="true">
            </po-input>
            <po-input #esptec2 class="po-md-4" formControlName="esptec2" name="esptec2" p-field-label="label"
                p-icon="ph ph-flask" p-auto-focus="true" p-label="Especificação Técnica 2" p-disabled="true">
            </po-input>
            <po-input #esptec3 class="po-md-4" formControlName="esptec3" name="esptec3" p-field-label="label"
                p-icon="ph ph-flask" p-auto-focus="true" p-label="Especificação Técnica 3" p-disabled="true">
            </po-input>
        </div>
        <div class="po-row">
            <po-input #esptec1 class="po-md-5" formControlName="tpMaterial" name="tpMaterial" p-field-label="label"
                p-auto-focus="true" p-label="Tipo Material" p-disabled="true">
            </po-input>
            <po-input #esptec2 class="po-md-5" formControlName="seguimento" name="seguimento" p-field-label="label"
                p-auto-focus="true" p-label="Seguimento" p-disabled="true">
            </po-input>
            <po-input #esptec3 class="po-md-2" formControlName="unidade" name="unidade" p-field-label="label"
                p-auto-focus="true" p-label="Unidade" p-disabled="true">
            </po-input>
        </div>
        <div class="po-row">
            <po-divider class="po-lg-12" p-label="Dados do Pedido"></po-divider>
            <div class="po-row">
                <po-number #esptec1 class="po-md-2" formControlName="quantidade" name="esptec1" p-field-label="label"
                    p-icon="ph ph-hash" p-auto-focus="true" p-label="Quantidade">
                </po-number>
                <po-input #esptec2 class="po-md-5" formControlName="preco" name="esptec2" p-field-label="label"
                    p-icon="ph ph-tag-simple" p-auto-focus="true" p-label="Preço Lista" p-disabled="true">
                </po-input>
                <po-input #esptec3 class="po-md-5" formControlName="total" name="esptec3" p-field-label="label"
                    p-icon="ph ph-sigma" p-auto-focus="true" p-label="Total" p-disabled="true">
                </po-input>
            </div>
        </div>
    </form>
    <po-modal-footer>
        <po-button p-label="Cancelar" (p-click)="closeModal()"> </po-button>
        <po-button p-label="Incluir" (p-click)="addItem()" [p-disabled]="!productForm.valid" p-kind="primary">
        </po-button>
    </po-modal-footer>
</po-modal>