import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { EnderecoDtoV2 } from "./endereco-dto";
import { CnpjDto } from "./cnpj-dto";
import { DistanceRequestDto } from "./distance-request-dto";
import { DistanceResponseDto } from "./distance-response-dto";
import { Cliente } from "../../home/clientes/cliente";
import { SimpleDistanceResponseDto } from "./simple-distance-request-dto";

@Injectable({ providedIn: 'root' })
export class CadastroHelperService {
    constructor(private http: HttpClient) { }

    getEnderecoByCep(cep: string) {
        return this.http.get<EnderecoDtoV2>(`${environment.apiUrl}${environment.versionApi}${environment.enderecoServiceApi}/${cep}`);
    }
    getInfoByCnpj(cnpj: string) {
        return this.http.get<CnpjDto>(`${environment.apiUrl}${environment.versionApi}/cadastrohelper/cnpjinfo/${cnpj}`);
    }
    getNewClient(cnpjOrCpf: string) {
        return this.http.get<Cliente>(`${environment.apiUrl}${environment.versionApi}/cadastrohelper/get-new-client/${cnpjOrCpf}`);
    }
    getDistanceBetweenCeps(distanceRequestDto: DistanceRequestDto) {
        return this.http.post<DistanceResponseDto>(
            `${environment.apiUrl}${environment.versionApi}/cadastrohelper/distance`,
            distanceRequestDto
        );
    }
    getDistanceBetweenCepsGoogle(distanceRequestDto: DistanceRequestDto) {
        return this.http.post<SimpleDistanceResponseDto>(
            `${environment.apiUrl}${environment.versionApi}/cadastrohelper/distance-google`,
            distanceRequestDto
        );
    }
}