import { Component, OnInit } from '@angular/core';
import { PoBreadcrumb } from '@po-ui/ng-components';
import { PoPageDynamicEditActions, PoPageDynamicEditField } from '@po-ui/ng-templates';
import { SchemaBuilderService } from '../../../core/menu/schema-builder.service';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'erpcloud-equipamento-edit',
    templateUrl: './equipamento-edit.component.html',
    styleUrls: ['./equipamento-edit.component.css']
})
export class EquipamentoEditComponent implements OnInit {

        public readonly serviceApi = environment.apiUrl + '/v1/equipamento';

        public readonly breadcrumb: PoBreadcrumb = {
            items: [{ label: 'Home', link: '/' }, { label: 'Cadastro de Equipamentos', link: '/equipamento' }, { label: 'Edição Equipamento' }]
        };
       
        //TODO: Implementar ações de salvar e salvar e novo
        public readonly actions: PoPageDynamicEditActions = {
            save: 'equipamento',
        };

        fields: PoPageDynamicEditField[] = []
        
        constructor(private schemaBuilderService: SchemaBuilderService) { }

        ngOnInit(): void {
            this.schemaBuilderService.getFieldSchema("equipamento").subscribe((data) => {
                this.fields = data;
            });
        }

}
