import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";


@Injectable({
    providedIn: 'root'
})
export class WppService {
    constructor(private http: HttpClient) { }

    sendMessageTemplate(whatsappNumber: string) {
        return this.http.get(`${environment.apiUrl}${environment.versionApi}/whatsapp/send-template/${whatsappNumber}`);
    }
    deleteMessage(idMessaWpp: number) {
        return this.http.delete(`${environment.apiUrl}${environment.versionApi}/whatsapp/${idMessaWpp}`);
    }
    retryMessage(idMessaWpp: number) {
        return this.http.get(`${environment.apiUrl}${environment.versionApi}/whatsapp/retry/${idMessaWpp}`);
    }

}