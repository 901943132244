import { Component, OnInit } from '@angular/core';
import { PoBreadcrumb } from '@po-ui/ng-components';
import { PoPageDynamicEditActions, PoPageDynamicEditField } from '@po-ui/ng-templates';
import { SchemaBuilderService } from '../../../core/menu/schema-builder.service';
import { environment } from '../../../../environments/environment';

@Component({
    selector: 'erpcloud-vendedor-edit',
    templateUrl: './vendedor-edit.component.html',
    styleUrls: ['./vendedor-edit.component.css']
})
export class VendedorEditComponent implements OnInit {

        public readonly serviceApi = environment.apiUrl + '/v1/vendedor';

        public readonly breadcrumb: PoBreadcrumb = {
            items: [{ label: 'Home', link: '/' }, { label: 'Cadastro de Vendedores', link: '/vendedor' }, { label: 'Edição de Vendedor' }]
        };
       
        //TODO: Implementar ações de salvar e salvar e novo
        public readonly actions: PoPageDynamicEditActions = {
            save: 'vendedor',
        };

        fields: PoPageDynamicEditField[] = []
        
        constructor(private schemaBuilderService: SchemaBuilderService) { }

        ngOnInit(): void {
            this.schemaBuilderService.getFieldSchema("vendedor").subscribe((data) => {
                this.fields = data;
            });
        }

}
