<po-page-dynamic-table
p-title="Cadastro de Vendedores"
[p-hide-columns-manager]="true"

[p-quick-search-width]="4"
[p-table-custom-actions]="tableCustomActions"
[p-breadcrumb]="breadcrumb"
[p-concat-filters]="true"
[p-infinite-scroll]="true"
[p-service-api]="serviceApi"
[p-actions]="actions"
[p-fields]="fields"
[p-actions-right] = "false"
[p-page-custom-actions]="pageCustomActions">

</po-page-dynamic-table>