import { NgModule } from "@angular/core";
import { ReceberComponent } from "./receber.component";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { PoModule } from "@po-ui/ng-components";
import { PoTemplatesModule } from "@po-ui/ng-templates";

@NgModule({
    declarations: [ReceberComponent],
    exports: [ReceberComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        PoModule,
        PoTemplatesModule,
    ]
})
export class ReceberModule { }