import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './home/login/login.component';
import { DashComponent } from './home/dash/dash.component';
import { LoginGuard } from './core/auth/login.guard';
import { NotLoggedGuard } from './core/auth/not-logged.guard';
import { ClienteComponent } from './home/clientes/cliente.component';
import { ClienteEditComponent } from './home/clientes/cliente-edit/cliente-edit.component';
import { FornecedorComponent } from './home/fornecedores/fornecedor.component';
import { FornecedorEditComponent } from './home/fornecedores/fornecedores-edit/fornecedor-edit.component';
import { TableSchemaResolver } from './core/schema/table-schema.resolver';
import { OrcamentoComponent } from './home/orcamento/orcamento.component';
import { CondpgtoComponent } from './home/condpgto/condpgto.component';
import { CondpgtoEditComponent } from './home/condpgto/condpgto-edit/condpgto-edit.component';
import { SectionsComponent } from './home/sections/sections.component';
import { SectionsEditComponent } from './home/sections/sections-edit/sections-edit.component';
import { BombasComponent } from './home/bombas/bombas.component';
import { BombasEditComponent } from './home/bombas/bombas-edit/bombas-edit.component';
import { FuncionarioComponent } from './home/funcionario/funcionario.component';
import { FuncionarioEditComponent } from './home/funcionario/funcionario-edit/funcionario-edit.component';
import { VendedorComponent } from './home/vendedor/vendedor.component';
import { VendedorEditComponent } from './home/vendedor/vendedor-edit/vendedor-edit.component';
import { EquipamentoComponent } from './home/equipamento/equipamento.component';
import { EquipamentoEditComponent } from './home/equipamento/equipamento-edit/equipamento-edit.component';
import { WppComponent } from './home/wpp/wpp.component';
import { ConfigComponent } from './home/config/config.component';
import { OrcamentoAprovaComponent } from './home/orcamento/orcamento-aprova/orcamento-aprova.component';
import { ReceberComponent } from './home/receber/receber.component';
import { CalculadoraComponent } from './home/calculadora/calculadora.component';
import { OrcamentoConsultaComponent } from './home/orcamento/orcamento-consulta/orcamento-consulta.component';
import { OrcamentoAprovaComercialComponent } from './home/orcamento/orcamento-aprova-comercial/orcamento-aprova-comercial.component';

const routes: Routes = [
  {
    path: '',
    component: LoginComponent,
    canActivate: [LoginGuard]
  },
  {
    path: 'home',
    component: DashComponent,
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'clientes',
    component: ClienteComponent,
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'clientes-edit',
    component: ClienteEditComponent,
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'clientes-edit/:id',
    component: ClienteEditComponent,
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'fornecedores',
    component: FornecedorComponent,
    resolve: { fields: TableSchemaResolver },
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'fornecedores-edit/:id',
    component: FornecedorEditComponent,
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'fornecedores-edit',
    component: FornecedorEditComponent,
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'condpgtos',
    component: CondpgtoComponent,
    resolve: { fields: TableSchemaResolver },
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'condpgtos-edit/:id',
    component: CondpgtoEditComponent,
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'condpgtos-edit',
    component: CondpgtoEditComponent,
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'sections',
    component: SectionsComponent,
    resolve: { fields: TableSchemaResolver },
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'sections-edit/:id',
    component: SectionsEditComponent,
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'sections-edit',
    component: SectionsEditComponent,
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'bombas',
    component: BombasComponent,
    resolve: { fields: TableSchemaResolver },
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'bombas-edit/:id',
    component: BombasEditComponent,
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'bombas-edit',
    component: BombasEditComponent,
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'vendedor',
    component: VendedorComponent,
    resolve: { fields: TableSchemaResolver },
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'vendedor-edit/:id',
    component: VendedorEditComponent,
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'vendedor-edit',
    component: VendedorEditComponent,
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'funcionario',
    component: FuncionarioComponent,
    resolve: { fields: TableSchemaResolver },
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'funcionario-edit/:id',
    component: FuncionarioEditComponent,
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'funcionario-edit',
    component: FuncionarioEditComponent,
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'equipamento',
    component: EquipamentoComponent,
    resolve: { fields: TableSchemaResolver },
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'equipamento-edit/:id',
    component: EquipamentoEditComponent,
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'equipamento-edit',
    component: EquipamentoEditComponent,
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'orcamento',
    component: OrcamentoComponent,
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'orcamento-aprova',
    component: OrcamentoAprovaComponent,
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'orcamento-aprova-comercial',
    component: OrcamentoAprovaComercialComponent,
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'orcamento-consulta',
    component: OrcamentoConsultaComponent,
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'wpp',
    component: WppComponent,
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'config',
    component: ConfigComponent,
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'receber',
    component: ReceberComponent,
    canActivate: [NotLoggedGuard]
  },
  {
    path: 'calculadora',
    component: CalculadoraComponent,
    canActivate: [NotLoggedGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
